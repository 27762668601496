import { Component, OnInit, HostListener, ViewChild, ElementRef, Inject, OnDestroy, SecurityContext } from '@angular/core';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute } from '@angular/router';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { MatDialog } from '@angular/material';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { BasePage } from '../base-page';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NoteComponent } from 'src/app/dialogs/note/note.component';
import { Subscription } from 'rxjs';
import { NavItem } from 'src/app/logic/base';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss']
})
export class NotesComponent extends BasePage implements OnInit, OnDestroy {
    @ViewChild('notesContainer', { static: true }) notesContainer: ElementRef;

    notes = new Map<number, any>();
    parentNote: any = null;
    displayedNotes = [];

    idParamSubscription: Subscription;

    constructor(
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        public nav: ForgeNavService,
        public world: WorldService,
        private modelService: ApiModelService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.nav.setNavItems("notes", []);
            this.getNotes();
        });
        this.idParamSubscription = this.route.paramMap.subscribe(data => this.updateBoard() );
    }

    ngOnDestroy() {
        this.idParamSubscription.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        
    }

    onDragStart(note: any) {
        note.startX = note.x;
        note.startY = note.y;
    }
    onMove(note: any, event: CdkDragMove) {
        note.x = note.startX + event.distance.x;
        note.y = note.startY + event.distance.y;
    }
    onRelease(note: any) {
        delete note.startX;
        delete note.startY;
        this.storage.set("note_pos_" + note.id, { x: note.x, y: note.y });
    }
    addNote(): void {
        var dialog = this.dialog.open(NoteComponent, {
            data: { title: "", text: "" }
        });
        dialog.afterClosed().subscribe(result => {
            if (result == null) return;
            let note = {
                worldId: this.world.id,
                title: result.title,
                text: result.text,
                parentId: this.parentNote ? this.parentNote.id : null
            };
            this.modelService.create("notes", note, () => this.getNotes());
        });
    }
    editNote(note: any): void {
        var dialog = this.dialog.open(NoteComponent, {
            data: { title: note.title, text: note.text }
        });
        dialog.afterClosed().subscribe(result => {
            if (result == null) return;
            note.title = result.title;
            note.text = result.text;
            this.modelService.update("notes", note, () => this.getNotes());
        });
    }
    deleteNote(note: any): void {
        this.confirm("This action is not reversible.", "Delete '" + note.title + "'?").subscribe((result) => {
            result && this.modelService.delete("notes", note.id, () => this.getNotes());
        });
    }
    getFirstParagraph(note: any): string {
        let doc = new DOMParser().parseFromString(note.text.replace("<div><!--block--><\/div>", ""), "text/html");
        let divs = doc.body.children;
        for (let i = 0; i < divs.length; i++) {
            let div: any = divs.item(i);
            if (div.innerText.length > 0) {
                return this.sanitizer.sanitize(SecurityContext.HTML, div.innerHTML);
            }
        }
        return "";
    }

    private getNotes(): void {
        this.modelService.getAll(
            "notes",
            { worldId: this.world.id },
            data => {
                this.notes.clear();
                this.parentNote = null;
                for (let note of data) this.notes.set(note.id, note);
                this.updateBoard();
            }
        );
    }

    private updateBoard(): void {
        let idParam = this.route.snapshot.paramMap.get("noteId");
        if (idParam == 'root') this.parentNote = null; else this.parentNote = this.notes.get(parseInt(idParam));

        let navItems = [];
        let n = this.parentNote;
        while (n != null) {
            navItems.push(new NavItem(n.title, "notes/" + n.id));
            n = this.notes.get(n.parentId);
        }
        navItems.reverse();
        this.nav.setNavItems("notes", navItems);
        this.displayedNotes = [];
        this.notes.forEach((note: any, id: number) => {
            if (this.storage.has("note_pos_" + id)) {
                let pos = this.storage.get("note_pos_" + id);
                note.x = pos.x;
                note.y = pos.y
            }
            let text = note.text.replace("<div><!--block--><\/div>", "").replace("<iframe", "<forge-vid").replace("iframe>", "forge-vid>");
            let doc = new DOMParser().parseFromString(text, "text/html");
            let divs = doc.body.children;
            for (let i = 0; i < divs.length; i++) {
                let div: any = divs.item(i);
                if (div.innerText.length > 0) break;
                let iframes = div.querySelectorAll("forge-vid");
                if (iframes.length > 0) {
                    note.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(iframes.item(0).attributes["src"].value);
                } else {
                    let images = div.querySelectorAll("img");
                    if (images.length > 0) {
                        note.imageUrl = this.sanitizer.bypassSecurityTrustUrl(images.item(0).attributes["src"].value);
                    }
                }
            }
            if ((note.parentId == null && this.parentNote == null) || (this.parentNote != null && note.parentId == this.parentNote.id)) {
                this.displayedNotes.push(note);
            }
        });
    }
}
