import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { BigTableColumns } from 'src/app/logic/utils';
import { NavItem } from 'src/app/logic/base';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { ApiRelationService } from 'src/app/logic/services/api-relation.service';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { RoleComponent } from 'src/app/dialogs/role/role.component';
import { BasePage } from '../base-page';

@Component({
    selector: 'app-book',
    templateUrl: './book.component.html'
})
export class BookComponent extends BasePage implements OnInit {
    @ViewChild('chaptersSort', { static: true }) chaptersSort: MatSort;
    chaptersCols: BigTableColumns = new BigTableColumns(['title', 'ordinal', 'actions'], ['ordinal']);
    chapters = new MatTableDataSource([]);

    @ViewChild('rolesSort', { static: true }) rolesSort: MatSort;
    @ViewChild('rolesPaginator', { static: true }) rolesPaginator: MatPaginator;
    rolesCols: BigTableColumns = new BigTableColumns(['character.name', 'description', 'priority', 'actions'], ['description', 'priority']);
    roles = new MatTableDataSource([]);

    bookForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        subtitle: new FormControl('', []),
        author: new FormControl('', [Validators.required]),
        seriesId: new FormControl(-1, []),
        seriesIndex: new FormControl(null, [Validators.pattern("[0-9]*")])
    });

    isbnForm = new FormGroup({
        isbn: new FormControl('', [Validators.required])
    })

    isbns = new Map<string, any>();
    series = [];
    idParam: any;
    isExisting: boolean = false;

    constructor(
        private forge: ForgeService,
        private world: WorldService,
        private modelService: ApiModelService,
        private relationService: ApiRelationService,
        private route: ActivatedRoute,
        private router: Router,
        public nav: ForgeNavService,
        dialog: MatDialog
    ) {
        super(dialog);
        this.nav = nav;
        this.roles.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'character.name': return item.character.name;
                default: return item[property];
            }
        };
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getBook();
            this.getSeries();
        });
        this.chapters.sort = this.chaptersSort;
        this.roles.sort = this.rolesSort;
        this.roles.paginator = this.rolesPaginator;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.chaptersCols.update();
        this.rolesCols.update();
    }

    save() {
        var value = this.bookForm.value;
        var body = {
            id: this.isExisting ? parseInt(this.idParam) : null,
            worldId: this.world.id,
            title: value.title,
            subtitle: value.subtitle,
            author: value.author,
            seriesId: (value.seriesId < 0) ? null : value.seriesId,
            seriesIndex: (value.seriesIndex == null) ? null : parseInt(value.seriesIndex),
            isbn: this.forge.mapModelToObject(this.isbns)
        };

        if (this.isExisting) {
            this.modelService.update("books", body, data => { });
        } else {
            this.modelService.create("books", body, data => {
                this.router.navigate([this.nav.getSubRoute("books/" + data.id)]);
                this.ngOnInit();
            });
        }
    }

    addIsbn() {
        var value = this.isbnForm.value;
        if (!this.isbns.has(value.isbn)) {
            this.isbns.set(value.isbn, { value: "" });
        }
        this.isbnForm.setValue({
            isbn: ""
        });
    }

    deleteIsbn(key: string) {
        this.isbns.delete(key);
    }

    editRole(role: any): void {
        if (role == null) {
            this.modelService.getAll("characters", { worldId: this.world.id }, (data: any) => {
                this.dialog.open(RoleComponent, {
                    data: {
                        character: null,
                        book: { id: parseInt(this.idParam), title: this.bookForm.value.title },
                        characters: data,
                        books: null,
                        role: "",
                        priority: null,
                        roles: this.roles.data
                    }
                }).afterClosed().subscribe(body => {
                    if (body) {
                        body.worldId = this.world.id;
                        this.relationService.create("roles", body, (data: any) => { this.getRoles(); });
                    }
                });
            });
        } else {
            this.dialog.open(RoleComponent, {
                data: {
                    character: role.character,
                    book: role.book,
                    characters: null,
                    books: null,
                    role: role.description,
                    priority: role.priority,
                    roles: this.roles.data
                }
            }).afterClosed().subscribe(body => {
                if (body) this.relationService.update("roles", body.character.id, body.book.id, body, (data: any) => { this.getRoles(); });
            });
        }
    }

    deleteRole(role: any): void {
        this.relationService.delete("roles", role.character.id, this.idParam, () => { this.getRoles(); });
    }

    deleteChapter(chapter: any): void {
        this.confirm("All information and feedback associated with this chapter will be removed. This action is not reversible.", "Delete '" + chapter.title + "'?").subscribe(
            result => result && this.modelService.delete("chapters", chapter.id, () => { this.getChapters(); })
        );
    }

    private getBook(): void {
        this.idParam = this.route.snapshot.paramMap.get("bookId");

        if (this.idParam == "new") {
            this.isExisting = false;
            this.nav.setNavItems(null, []);
            this.bookForm.setValue({
                title: '',
                subtitle: '',
                author: '',
                seriesId: -1,
                seriesIndex: null
            });
            this.isbns = this.forge.objectToMapModel({});
        } else {
            this.isExisting = true;
            this.modelService.get("books", parseInt(this.idParam), data => {
                this.nav.setNavItems("books", [
                    new NavItem(data.title, "books/" + data.id)
                ]);
                this.getChapters();
                this.getRoles();
                this.bookForm.setValue({
                    title: data.title,
                    subtitle: data.subtitle,
                    author: data.author,
                    seriesId: data.seriesId ? data.seriesId : -1,
                    seriesIndex: data.seriesIndex
                });
                this.isbns = this.forge.objectToMapModel(data.isbn);
            });
        }
    }

    private getChapters(): void {
        let id = parseInt(this.idParam);
        this.modelService.getAll(
            "chapters",
            { bookId: id },
            data => {
                this.chapters.data = data;
            }
        );
    }

    private getRoles(): void {
        let id = parseInt(this.idParam);
        this.relationService.getAll(
            "roles",
            { bookId: id },
            data => {
                this.roles.data = data;
            }
        );
    }

    private getSeries(): void {
        this.modelService.getAll(
            "series",
            { worldId: this.world.id },
            data => {
                this.series = data;
            }
        );
    }
}
