import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
    selector: 'forge-book-dialog',
    templateUrl: './book.component.html'
})
export class BookDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<ConfirmComponent>) { }
}
