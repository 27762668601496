import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute } from '@angular/router';
import { BigTableColumns } from 'src/app/logic/utils';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { BasePage } from '../base-page';

@Component({
    selector: 'app-calendars',
    templateUrl: './calendars.component.html'
})
export class CalendarsComponent extends BasePage implements OnInit {
    @ViewChild('calendarsSort', { static: true }) calendarsSort: MatSort;
    calendarsCols: BigTableColumns = new BigTableColumns([], ['epoch', 'months']);
    calendars = new MatTableDataSource([]);

    constructor(
        private modelService: ApiModelService,
        private route: ActivatedRoute,
        public world: WorldService,
        public nav: ForgeNavService,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.nav.setNavItems("calendars", []);
            this.getCalendars();
        });
        this.calendars.sort = this.calendarsSort;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.calendarsCols.update();
    }

    deleteCalendar(calendar: any): void {
        this.confirm("This action is not reversible.", "Delete '" + calendar.name + "'?").subscribe((result) => {
            result && this.modelService.delete("calendars", calendar.id, () => this.getCalendars());
        });
    }

    getMonthCount(calendar: any): number {
        return calendar.additional.__desc__.months.length;
    }

    private getCalendars(): void {
        this.modelService.getAll(
            "calendars",
            { worldId: this.world.id },
            data => {
                let displayedColumns = ['name', 'epoch', 'months', 'actions'];
                this.calendarsCols.columns = displayedColumns;
                this.calendarsCols.update();
                this.calendars.data = data;
            }
        );
    }
}
