import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';
import { AlertComponent } from '../dialogs/alert/alert.component';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

export class BasePage {
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    dialog: MatDialog;

    constructor(dialog: MatDialog) {
        this.dialog = dialog;
    }

    confirm(message: string, title: string): Observable<boolean> {
        var dialog = this.dialog.open(ConfirmComponent, {
            data: { message: message, title: title }
        });
        return dialog.afterClosed();
    }

    alert(message: string, title: string): Observable<any> {
        var dialog = this.dialog.open(AlertComponent, {
            data: { message: message, title: title }
        });
        return dialog.afterClosed();
    }
}
