import { Component, Input, OnDestroy, Optional, Self, ElementRef } from '@angular/core';
import { FormBuilder, NgControl, ControlValueAccessor, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { Calendar } from 'src/app/logic/base';
import { CalendarDate } from 'src/app/logic/utils';

@Component({
    selector: 'forge-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [{provide: MatFormFieldControl, useExisting: DatePickerComponent}],
    host: {
      '[class.example-floating]': 'shouldLabelFloat',
      '[id]': 'id',
      '[attr.aria-describedby]': 'describedBy',
    }
  })
export class DatePickerComponent implements ControlValueAccessor, MatFormFieldControl<number>, OnDestroy {
    static nextId = 0;

    parts: FormGroup;
    stateChanges = new Subject<void>();
    focused = false;
    errorState = false;
    controlType = 'forge-date-picker';
    id = `forge-date-picker-${DatePickerComponent.nextId++}`;
    describedBy = '';

    onChange = (_: any) => { };
    onTouched = () => { };

    get empty() {
        const { value: { year, month, day, value } } = this.parts;
        return !year && !value;
    }

    get shouldLabelFloat() { return this.focused || !this.empty; }

    dayElement: any;

    @Input()
    get placeholder(): string { return this._placeholder; }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    private _placeholder: string;

    @Input()
    get required(): boolean { return this._required; }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    private _required = false;

    @Input()
    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.parts.disable() : this.parts.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    @Input()
    get value(): number | null {
        const { value: { year, month, day, value } } = this.parts;
        if (!this._calendar) {
            let val: number = parseInt(value);
            return isNaN(val) ? null : val;
        }

        if (year == null || month == null || day == null || (year.length == 0 && month.length == 0 && day.length == 0)) return null;
        let numYear: number = parseInt(year);
        let numMonth: number = parseInt(month);
        let numDay: number = parseInt(day);
        if (isNaN(numYear) && !isNaN(numMonth) && !isNaN(numDay)) return NaN;

        let cdate: CalendarDate = new CalendarDate(null, this._calendar);
        cdate.year = numYear;
        cdate.month = numMonth;
        cdate.day = numDay;
        return cdate.toDays();
    }
    set value(days: number | null) {
        if (!days) {
            this.parts.setValue({year: "", month: "", day: "", value: ""});
        } else if (!this._calendar) {
            this.parts.setValue({year: "", month: "", day: "", value: "" + days});
        } else {
            let cdate: CalendarDate = new CalendarDate(days, this._calendar);
            this.parts.setValue(
                {
                    year: cdate.year != null ? "" + cdate.year : "",
                    month: cdate.month != null ? "" + cdate.month : "",
                    day: cdate.day != null ? "" + cdate.day : "",
                    value: this.value ? "" + this.value : ""
                }
            );
        }
        this.stateChanges.next();
    }

    @Input()
    get calendar(): Calendar | null {
        return this._calendar;
    }
    set calendar(calendar: Calendar | null) {
        if (calendar != null) {
            let cdate: CalendarDate = new CalendarDate(this.value, calendar);
            this.parts.setValue(
                {
                    year: cdate.year != null ? "" + cdate.year : "",
                    month: cdate.month != null ? "" + cdate.month : "",
                    day: cdate.day != null ? "" + cdate.day : "",
                    value: this.value ? "" + this.value : ""
                }
            );
        }
        this._calendar = calendar;
    }
    private _calendar: Calendar;

    constructor(
        formBuilder: FormBuilder,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Self() public ngControl: NgControl
    ) {
        this.parts = formBuilder.group({
            year: new FormControl("", [Validators.required]),
            month: new FormControl("", [Validators.required]),
            day: new FormControl("", [Validators.required]),
            value: new FormControl("", [Validators.required])
        });
        _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
            if (this.focused && !origin) {
                this.onTouched();
            }
            this.focused = !!origin;
            this.stateChanges.next();
        });

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
    }

    getMonths(): any[] {
        if (this.calendar) return this.calendar.additional.__desc__.months;
        return [];
    }

    getSelectedMonth(): any {
        let monthValue = this.getMonths()[this.parts.get("month").value];
        return monthValue;
    }

    getMonthText(): string {
        let monthValue = this.getSelectedMonth();
        if (monthValue) {
            return monthValue.name;
        }
        return "Select Month";
    }

    selectMonth(month: number | null): void {
        if (month == null) this.parts.patchValue({ month: "" }); else this.parts.patchValue({ month: "" + month });
        this._handleInput();
        setTimeout(() => { this._elementRef.nativeElement.querySelectorAll('input').item(1)!.focus(); }, 0);
    }

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() != 'input') {
            const { value: { year, month, day, value } } = this.parts;
            if (year == "") {
                this._elementRef.nativeElement.querySelector('input')!.focus();
            } else if (month == "") {
                this._elementRef.nativeElement.querySelector('a')!.focus();
            } else {
                this._elementRef.nativeElement.querySelectorAll('input').item(1)!.focus();
            }
        }
    }

    writeValue(val: number | null): void {
        this.value = val;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    _handleInput(): void {
        this.onChange(this.value);
    }
}
