import { Component } from '@angular/core';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { BasePage } from '../base-page';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-world',
  templateUrl: './world.component.html',
  styleUrls: ['./world.component.scss']
})
export class WorldComponent extends BasePage {
    worldForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      calendar: new FormControl('-1', [])
    });

    calendars = [];
    idParam:any;

    constructor(
        public nav: ForgeNavService,
        public world: WorldService,
        private modelService: ApiModelService,
        private route: ActivatedRoute,
        private router: Router,
        dialog: MatDialog
    ) {
        super(dialog);
        this.getWorld();
    }

    private getWorld(): void {
        this.idParam = this.route.snapshot.paramMap.get("worldId");
        this.nav.setNavItems("", []);

        if (this.idParam == "new") {
            this.worldForm.setValue({
                name: 'My World',
                description: '',
                calendar: -1
            });
        } else {
            this.modelService.get("worlds", parseInt(this.idParam), data => {
                this.world.set(data);
                this.nav.setNavItems("", []);
                this.getCalendars();
                this.worldForm.setValue({
                    name: this.world.name,
                    description: this.world.description,
                    calendar: this.world.calendarId ? this.world.calendarId : -1
                });
            });
        }
    }

    onSubmit() {
        var value = this.worldForm.value;
        var world = {
            id: this.world.id,
            name: value.name,
            description: value.description,
            calendarId: (value.calendar < 0) ? null : value.calendar
        };
        if (this.world) {
            this.modelService.update("worlds", world, data => { });
        } else {
            this.modelService.create("worlds", world, data => {
                this.router.navigate(['/worlds/' + data.id]);
            });
        }
    }

    private getCalendars(): void {
        this.modelService.getAll(
            "calendars",
            { worldId: this.world.id },
            data => {
                this.calendars = data;
            }
        );
    }
}
