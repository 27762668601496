import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ForgeService } from 'src/app/logic/services/forge.service';

@Component({
    selector: 'app-editor',
    templateUrl: './note.component.html'
})
export class NoteComponent {

    noteForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        text: new FormControl('', [])
    });
    
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public forge: ForgeService,
        public dialog: MatDialogRef<NoteComponent>
    ) {
        this.noteForm.setValue({
            title: data.title,
            text: data.text
        });
    }

}
