import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { WorldService } from 'src/app/logic/services/world.service';

@Component({
    selector: 'app-path',
    templateUrl: './path.component.html'
})
export class PathComponent {
    loadingSub: Subscription;

    pathForm = new FormGroup({
        destinationId: new FormControl(null, [Validators.required]),
        title: new FormControl("", [Validators.required]),
        distance: new FormControl("", [Validators.required, Validators.pattern("[0-9]*")]),
        reversible: new FormControl(true, []),
        notes: new FormControl("", []),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public forge: ForgeService,
        public world: WorldService,
        public dialog: MatDialogRef<PathComponent>
    ) {
        this.pathForm.setValue({
            destinationId: this.data.destinationId,
            title: this.data.title,
            distance: this.data.distance,
            reversible: this.data.reversible,
            notes: this.data.notes
        });
        if (data.places) {
            let places = [];
            for (let place of data.places) {
                if (place.id != data.originId) places.push(place);
            }
            data.places = places;
        }
    }

    onSubmit() {
        var value = this.pathForm.value;
        var body = {
            worldId: this.world.id,
            origin: {
                id: this.data.originId
            },
            destination: {
                id: value.destinationId
            },
            title: value.title,
            distance: parseInt(value.distance),
            reversible: value.reversible,
            notes: value.notes
        };
        this.dialog.close(body);
    }
}
