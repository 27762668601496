import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute } from '@angular/router';
import { BigTableColumns } from 'src/app/logic/utils';
import { MatSort, MatTableDataSource, MatDialog, MatPaginator } from '@angular/material';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { BasePage } from '../base-page';

@Component({
    selector: 'app-readers',
    templateUrl: './readers.component.html'
})
export class ReadersComponent extends BasePage implements OnInit {
    @ViewChild('readersSort', { static: true }) readersSort: MatSort;
    @ViewChild('readersPaginator', { static: true }) readersPaginator: MatPaginator;
    readersCols: BigTableColumns = new BigTableColumns([], ['email']);
    readers = new MatTableDataSource([]);

    constructor(
        public nav: ForgeNavService,
        public world: WorldService,
        private modelService: ApiModelService,
        private route: ActivatedRoute,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.nav.setNavItems("readers", []);
            this.getReaders();
        });
        this.readers.sort = this.readersSort;
        this.readers.paginator = this.readersPaginator;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.readersCols.update();
    }

    deleteReader(reader: any): void {
        this.confirm("All reader's feedback will also be deleted. This action is not reversible.", "Delete '" + reader.name + "'?").subscribe((result) => {
            result && this.modelService.delete("readers", reader.id, () => this.getReaders());
        });
    }

    private getReaders(): void {
        this.modelService.getAll(
            "readers",
            { worldId: this.world.id },
            data => {
                let displayedColumns = ['name', 'email', 'actions'];
                this.readersCols.columns = displayedColumns;
                this.readersCols.update();
                this.readers.data = data;
            }
        );
    }
}
