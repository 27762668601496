import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-locale',
    templateUrl: './locale.component.html'
})
export class LocaleComponent {
    loadingSub: Subscription;

    availablePlaces = [];
    availableEvents = [];
    title: string;

    localeForm = new FormGroup({
        placeId: new FormControl(-1, [Validators.required]),
        eventId: new FormControl(-1, [Validators.required]),
        role: new FormControl("", [])
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<LocaleComponent>
    ) {
        this.localeForm.setValue({
            placeId: data.place ? data.place.id : -1,
            eventId: data.event ? data.event.id : -1,
            role: data.role
        });
        if (data.places) {
            this.title = "Locale: " + data.event.title;
            for (let place of data.places) {
                let found: boolean = false;
                for (let locale of data.locales) {
                    if (locale.place.id == place.id) {
                        found = true;
                        break;
                    }
                }
                if (!found) this.availablePlaces.push(place);
            }
        } else if (data.events) {
            this.title = "Associate with " + data.place.name;
            for (let event of data.events) {
                let found: boolean = false;
                for (let locale of data.locales) {
                    if (locale.event.id == event.id) {
                        found = true;
                        break;
                    }
                }
                if (!found) this.availableEvents.push(event);
            }
        } else {
            this.title = data.place.name + " (" + data.event.title + ")";
        }
    }

    onSubmit() {
        var value = this.localeForm.value;
        var body = {
            place: {
                id: value.placeId
            },
            event: {
                id: value.eventId
            },
            role: value.role
        };
        this.dialog.close(body);
    }
}
