import { Calendar } from './base';
import { ErrorStateMatcher } from '@angular/material';
import { FormGroupDirective, FormControl, NgForm } from '@angular/forms';

export class CalendarDate {
    public year: number | null;
    public month: number | null;
    public day: number | null;

    constructor(days: number | null, private cal: Calendar | null) {
        if (cal == null || days == null) {
            this.year = null;
            this.month = null;
            this.day = null;
            return;
        }

        let daysInYear = this.getDaysInYear();
        let desc = this.cal.additional.__desc__;
        let epochDay = (days - this.cal.epoch + 1);
        let yearOffset = 0;

        while (epochDay <= 0) {
            epochDay += daysInYear;
            yearOffset--;
        }

        let dayInYear = (epochDay - 1) % daysInYear;
        this.year = Math.ceil(epochDay / daysInYear) + yearOffset;

        for (let k = 0; k < desc.months.length; k++) {
            let month = desc.months[k];
            if (dayInYear < month.days) {
                this.month = k;
                this.day = dayInYear + 1;
                break;
            } else {
                dayInYear -= month.days;
            }
        }
    }

    public toDays(): number {
        let daysInYear = this.getDaysInYear();
        let day = 0;
        for (let i = 0; i < this.month; i++) {
            day += this.cal.additional.__desc__.months[i].days;
        }
        day += this.day;
        return ((this.year - 1) * daysInYear + day - 1) + this.cal.epoch;
    }

    public toString(): string {
        if (this.month == null) return "";
        var month = this.cal.additional.__desc__.months[this.month];
        var ret = month.name;
        if (month.days > 1) {
            ret += " " + this.day;
        }
        return ret + ", " + this.year;
    };

    public getDaysInYear(): number {
        var desc = this.cal.additional.__desc__;
        var daysInYear = 0;
        for (var k in desc.months) {
            daysInYear += desc.months[k].days;
        }
        return daysInYear;
    };
}

export class BigTableColumns {
    public visible: any[] = [];

    constructor(public columns: any[], private expendables: any[]) {
        this.visible = columns;
    }

    public update() {
        if (window.innerWidth >= 900) {
            this.visible = this.columns;
            return;
        }

        let displayColumns = [];
        for (let col of this.columns) {
            var isExpendable: boolean = false;
            for (let ex of this.expendables) {
                if (col == ex) {
                    isExpendable = true;
                    break;
                }
            }
            if (isExpendable) continue;
            displayColumns.push(col);
        }
        this.visible = displayColumns;
    }
}

export class CrossFieldErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.dirty && form.invalid;
    }
}
