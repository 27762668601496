import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { WorldService } from 'src/app/logic/services/world.service';

@Component({
    selector: 'app-relationship',
    templateUrl: './relationship.component.html'
})
export class RelationshipComponent {
    loadingSub: Subscription;

    availableCharacters = [];
    title: string;

    relationshipForm = new FormGroup({
        characterId: new FormControl(null, [Validators.required]),
        relativeId: new FormControl(null, [Validators.required]),
        relation: new FormControl("", [Validators.required]),
        nickname: new FormControl("", []),
        notes: new FormControl("", []),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public forge: ForgeService,
        public world: WorldService,
        public dialog: MatDialogRef<RelationshipComponent>
    ) {
        this.relationshipForm.setValue({
            characterId: this.data.character ? this.data.character.id : null,
            relativeId: this.data.relative ? this.data.relative.id : null,
            relation: this.data.relation,
            nickname: this.data.nickname == null ? "" : this.data.nickname,
            notes: this.data.notes
        });
        if (this.data.characters) {
            for (let character of this.data.characters) {
                let found: boolean = character.id == this.data.character.id;
                if (!found) {
                    for (let relationship of data.relationships) {
                        if (relationship.relative.id == character.id) {
                            found = true;
                            break;
                        }
                    }
                }
                if (!found) this.availableCharacters.push(character);
            }
            this.title = "Add Relationship";
        } else {
            this.title = "Relationship with " + data.relative.name;
        }
    }

    onSubmit() {
        var value = this.relationshipForm.value;
        var body = {
            worldId: this.world.id,
            character: {
                id: value.characterId
            },
            relative: {
                id: value.relativeId,
                nickname: value.nickname == "" ? null : value.nickname,
                relation: value.relation
            },
            notes: value.notes
        };
        this.dialog.close(body);
    }
}
