import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'forge-countdown',
    templateUrl: './countdown.component.html'
})
export class CountdownComponent implements OnInit, OnDestroy {

    private counter: BehaviorSubject<number>;
    private interval: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<CountdownComponent>
    ) {
        this.counter = new BehaviorSubject<number>(data.count);
    }

    ngOnInit(): void {
        this.interval = setInterval(() => {
            let value = this.counter.getValue();
            if (value <= 1) {
                this.dialog.close();
                return;
            }
            this.counter.next(value - 1);
        }, 1000);
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }

    getMessage() : string {
        return this.data.message.replace("{}", this.counter.getValue());
    }
}
