import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAutocompleteSelectedEvent, MatDialog, MatInput } from '@angular/material';
import { NavItem, Calendar } from 'src/app/logic/base';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { ApiRelationService } from 'src/app/logic/services/api-relation.service';
import { BasePage } from '../base-page';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CalendarDate } from 'src/app/logic/utils';

@Component({
    selector: 'app-chapter',
    templateUrl: './chapter.component.html'
})
export class ChapterComponent extends BasePage implements OnInit {
    @ViewChild('eventInput', { static: true }) eventInput: MatInput;
    newEventControl = new FormControl("");

    chapterEventIds: number[] = [];
    otherEventIds: number[] = [];
    filteredOtherEventIds: Observable<number[]>;
    events = [];

    calendar: Calendar = null;
    chapter: any = null;
    idParam: any;
    isExisting: boolean = false;
    newEvent: string;

    chapterForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        ordinal: new FormControl(null, [Validators.required, Validators.pattern("[0-9]*")]),
        startDay: new FormControl(null, []),
        endDay: new FormControl(null, []),
        synopsis: new FormControl('', [])
    }, (fg: FormGroup) => {
        const start = fg.get('startDay').value;
        const end = fg.get('endDay').value;
        if (!start || !end) return null;
        return start <= end ? null : { rangeValidator: "End date cannot be before start date." };
    });

    constructor(
        private world: WorldService,
        private modelService: ApiModelService,
        private relationService: ApiRelationService,
        private route: ActivatedRoute,
        private router: Router,
        public forge: ForgeService,
        public nav: ForgeNavService,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getCalendar();
            this.getBook();
        });
        this.filteredOtherEventIds = this.newEventControl.valueChanges.pipe(
            startWith(""),
            map(value => this._filter(value))
        );
    }

    save() {
        let bookId = this.route.snapshot.paramMap.get("bookId");
        var value = this.chapterForm.value;
        var body = {
            id: this.isExisting ? parseInt(this.idParam) : null,
            worldId: this.world.id,
            bookId: parseInt(bookId),
            title: value.title,
            startDay: value.startDay,
            endDay: value.endDay,
            synopsis: value.synopsis,
            ordinal: (value.ordinal == null) ? null : parseInt(value.ordinal),
            eventIds: this.chapterEventIds
        };

        if (this.isExisting) {
            this.modelService.update("chapters", body, data => { });
        } else {
            this.modelService.create("chapters", body, data => {
                this.router.navigate([this.nav.getSubRoute("books/" + bookId + "/chapters/" + data.id)]);
                this.ngOnInit();
            });
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        for (let item of this.events) {
            if (item && item.title == event.option.viewValue) {
                this.addEvent(item.id);
                break;
            }
        }
        this.newEventControl.setValue("");
        (<any>this.eventInput).nativeElement.value = "";
    }

    addEvent(id: number): void {
        let i: number = this.otherEventIds.indexOf(id);
        if (i < 0) return;
        this.chapterEventIds.push(id);
        this.otherEventIds.splice(i, 1);
    }

    removeEvent(id: number): void {
        let i: number = this.chapterEventIds.indexOf(id);
        if (i < 0) return;
        this.otherEventIds.push(id);
        this.chapterEventIds.splice(i, 1);
    }

    private _filter(value: string): number[] {
        const filterValue = value ? value.toLowerCase() : "";
        return this.otherEventIds.filter(option => this.events[option] ? this.events[option].title.toLowerCase().includes(filterValue) : false);
    }

    private getBook(): void {
        let bookId = this.route.snapshot.paramMap.get("bookId");
        this.modelService.get("books", parseInt(bookId), data => {
            this.getChapter(data);
        });
    }

    private getCalendar(): void {
        if (!this.world.calendarId) return;
        this.modelService.get("calendars", this.world.calendarId, data => {
            this.calendar = data;
            this.calendar.daysInYear = new CalendarDate(null, this.calendar).getDaysInYear();
        });
    }

    private getChapter(book: any): void {
        this.idParam = this.route.snapshot.paramMap.get("chapterId");

        if (this.idParam == "new") {
            this.isExisting = false;
            this.nav.setNavItems("books", [
                new NavItem(book.title, "books/" + book.id)
            ]);
            this.chapterForm.setValue({
                title: "",
                synopsis: "",
                ordinal: "",
                startDay: null,
                endDay: null
            });
            this.chapterEventIds = [];
            this.getEvents();
        } else {
            this.isExisting = true;
            this.modelService.get("chapters", parseInt(this.idParam), data => {
                this.nav.setNavItems("books", [
                    new NavItem(book.title, "books/" + book.id),
                    new NavItem(data.title, "books/" + book.id + "/chapters/" + data.id)
                ]);
                this.chapterForm.setValue({
                    title: data.title,
                    synopsis: data.synopsis,
                    ordinal: data.ordinal,
                    startDay: data.startDay,
                    endDay: data.endDay
                });
                this.chapterEventIds = data.eventIds;
                this.getEvents();
            });
        }
    }

    private getEvents(): void {
        this.relationService.getAll(
            "events",
            { worldId: this.world.id },
            data => {
                let otherEventIds = [];
                let events = [];
                for (let item of data) {
                    if (this.chapterEventIds.indexOf(item.id) < 0) otherEventIds.push(item.id);
                    events[item.id] = item;
                }
                this.events = events;
                this.otherEventIds = otherEventIds;
            }
        );
    }
}
