import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ApiService } from './logic/services/api.service';
import { ForgeNavService } from './logic/services/forge-nav.service';
import { WorldService } from './logic/services/world.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    screenWidth = 0;
    world: WorldService;
    api: ApiService;
    router: Router;
    nav: ForgeNavService;
    showWorldsButton = true;

    constructor(
        world: WorldService,
        api: ApiService,
        router: Router,
        nav: ForgeNavService
    ) {
        this.world = world;
        this.api = api;
        this.router = router;
        this.nav = nav;
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.showWorldsButton = !(event.url.startsWith("/questionnaire") || event.url.startsWith("/beta-signup"));
            }
        });
    }

    ngOnInit() {
        this.screenWidth = window.innerWidth;
        this.nav.setNavItems(null, []);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.screenWidth = window.innerWidth;
    }

    isBigScreen(): boolean {
        return this.screenWidth > 800;
    }

    signOut() {
        this.api.signOut();
        this.router.navigate(['/worlds']);
    }

}
