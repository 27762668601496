import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute } from '@angular/router';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { BigTableColumns } from 'src/app/logic/utils';
import { MatSort, MatTableDataSource, MatDialog, MatPaginator } from '@angular/material';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { BasePage } from '../base-page';

@Component({
    selector: 'app-betas',
    templateUrl: './betas.component.html'
})
export class BetasComponent extends BasePage implements OnInit {
    @ViewChild('betasSort', { static: true }) betasSort: MatSort;
    @ViewChild('betasPaginator', { static: true }) betasPaginator: MatPaginator;
    betasCols: BigTableColumns = new BigTableColumns([], ['book.title', 'startDate', 'endDate']);
    betas = new MatTableDataSource([]);

    constructor(
        private modelService: ApiModelService,
        private route: ActivatedRoute,
        public world: WorldService,
        public forge: ForgeService,
        public nav: ForgeNavService,
        dialog: MatDialog
    ) {
        super(dialog);
        this.betas.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'book.title': return item.book.title;
                default: return item[property];
            }
        };
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.nav.setNavItems("betas", []);
            this.getBetas();
        });
        this.betas.sort = this.betasSort;
        this.betas.paginator = this.betasPaginator;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.betasCols.update();
    }

    deleteBeta(beta: any): void {
        this.confirm("This action is not reversible.", "Delete '" + beta.name + "'?").subscribe((result) => {
            result && this.modelService.delete("betas", beta.id, () => this.getBetas());
        });
    }

    private getBetas(): void {
        this.modelService.getAll(
            "betas",
            { worldId: this.world.id },
            data => {
                let displayedColumns = ['name', 'book.title', 'startDate', 'endDate', 'actions'];
                this.betasCols.columns = displayedColumns;
                this.betasCols.update();
                this.betas.data = data;
            }
        );
    }
}
