import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoaderService } from './logic/services/loader.service';
import { LoaderInterceptor } from './logic/services/loader.interceptor';
import { LoaderComponent } from './components/loader/loader.component';
import { WorldsComponent } from './pages/worlds/worlds.component';
import { LoginComponent } from './dialogs/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ForgeService } from './logic/services/forge.service';
import { AlertComponent } from './dialogs/alert/alert.component';
import { ApiModelService } from './logic/services/api-model.service';
import { ApiRelationService } from './logic/services/api-relation.service';
import { ApiDataEndpointService } from './logic/services/api-dep.service';
import { ConfirmComponent } from './dialogs/confirm/confirm.component';
import { WorldComponent } from './pages/world/world.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SeriesComponent } from './pages/series/series.component';
import { BookDialogComponent } from './dialogs/book/book.component';
import { BooksComponent } from './pages/books/books.component';
import { WorldService } from './logic/services/world.service';
import { ApiService } from './logic/services/api.service';
import { ForgeNavService } from './logic/services/forge-nav.service';
import { BookComponent } from './pages/book/book.component';
import { RoleComponent } from './dialogs/role/role.component';
import { ChapterComponent } from './pages/chapter/chapter.component';
import { NgxSummernoteModule } from "ngx-summernote";
import { CalendarComponent } from './pages/calendar/calendar.component';
import { CalendarsComponent } from './pages/calendars/calendars.component';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { TimelineComponent } from './pages/timeline/timeline.component';
import { CharactersComponent } from './pages/characters/characters.component';
import { EventsComponent } from './pages/events/events.component';
import { GroupsComponent } from './pages/groups/groups.component';
import { ReadersComponent } from './pages/readers/readers.component';
import { TreeModule } from 'angular-tree-component';
import { PlacesComponent } from './pages/places/places.component';
import { BetasComponent } from './pages/betas/betas.component';
import { NotesComponent } from './pages/notes/notes.component';
import { NoteComponent } from './dialogs/note/note.component';
import { GroupComponent } from './pages/group/group.component';
import { MembershipComponent } from './dialogs/membership/membership.component';
import { InvolvementComponent } from './dialogs/involvement/involvement.component';
import { EventComponent } from './pages/event/event.component';
import { LocaleComponent } from './dialogs/locale/locale.component';
import { ResidencyComponent } from './dialogs/residency/residency.component';
import { CharacterComponent } from './pages/character/character.component';
import { RelationshipComponent } from './dialogs/relationship/relationship.component';
import { ApiAvatarService } from './logic/services/api-avatar.service';
import { PlaceComponent } from './pages/place/place.component';
import { PathComponent } from './dialogs/path/path.component';
import { ReaderComponent } from './pages/reader/reader.component';
import { ReadingComponent } from './dialogs/reading/reading.component';
import { FeedbacksComponent } from './pages/feedbacks/feedbacks.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { BarRatingModule } from "ngx-bar-rating";
import { BetaComponent } from './pages/beta/beta.component';
import { QuestionsComponent } from './pages/questions/questions.component';
import { ConditionComponent } from './dialogs/condition/condition.component';
import { MeComponent } from './pages/me/me.component';
import { IndexComponent } from './pages/index/index.component';
import { QuestionnaireComponent } from './pages/questionnaire/questionnaire.component';
import { ForgeQuestionComponent } from './components/question/forge-question.component';
import { QuestionnaireOverviewComponent } from './pages/questionnaire-overview/questionnaire-overview.component';
import { BetaSignUpComponent } from './pages/beta-signup/beta-signup.component';
import { BetaApplicationComponent } from './pages/beta-application/beta-application.component';
import { BetaApprovalComponent } from './dialogs/beta-approval/beta-approval.component';
import { BetaReportComponent } from './pages/beta-report/beta-report.component';
import { BetaChapterReportComponent } from './pages/beta-chapter-report/beta-chapter-report.component';
import { CountdownComponent } from './dialogs/countdown/countdown.component';

@NgModule({
    declarations: [
        AppComponent,
        AlertComponent,
        BetaSignUpComponent,
        BetaApplicationComponent,
        BetaApprovalComponent,
        BetaComponent,
        BetasComponent,
        BetaReportComponent,
        BetaChapterReportComponent,
        BookComponent,
        BooksComponent,
        BookDialogComponent,
        CalendarComponent,
        CalendarsComponent,
        ChapterComponent,
        CharacterComponent,
        CharactersComponent,
        ConditionComponent,
        ConfirmComponent,
        CountdownComponent,
        DatePickerComponent,
        EventComponent,
        EventsComponent,
        FeedbackComponent,
        FeedbacksComponent,
        ForgeQuestionComponent,
        GroupComponent,
        GroupsComponent,
        IndexComponent,
        InvolvementComponent,
        LoaderComponent,
        LocaleComponent,
        LoginComponent,
        MeComponent,
        MembershipComponent,
        NoteComponent,
        NotesComponent,
        NotFoundComponent,
        PathComponent,
        PlaceComponent,
        PlacesComponent,
        QuestionnaireComponent,
        QuestionnaireOverviewComponent,
        QuestionsComponent,
        ReaderComponent,
        ReadersComponent,
        ReadingComponent,
        RelationshipComponent,
        ResidencyComponent,
        RoleComponent,
        SeriesComponent,
        TimelineComponent,
        WorldComponent,
        WorldsComponent,
    ],
    imports: [
        AppRoutingModule,
        BarRatingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        MaterialModule,
        HttpClientModule,
        MglTimelineModule,
        NgxSummernoteModule,
        TreeModule.forRoot(),
        ReactiveFormsModule
    ],
    entryComponents: [
        AlertComponent,
        BetaApprovalComponent,
        BookDialogComponent,
        ConditionComponent,
        ConfirmComponent,
        CountdownComponent,
        DatePickerComponent,
        ForgeQuestionComponent,
        InvolvementComponent,
        LoaderComponent,
        LocaleComponent,
        LoginComponent,
        MembershipComponent,
        NoteComponent,
        PathComponent,
        ReadingComponent,
        RelationshipComponent,
        ResidencyComponent,
        RoleComponent
    ],
    providers: [
        ApiService,
        ApiAvatarService,
        ApiModelService,
        ApiRelationService,
        ApiDataEndpointService,
        CookieService,
        ForgeNavService,
        ForgeService,
        LoaderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        },
        WorldService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
