import { Injectable } from '@angular/core';
import { WorldService } from './world.service';
import { NavItem, TOP_LEVEL_MENU } from '../base';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ForgeNavService {
    private navItems: NavItem[] = [];
    public title = "World Forge";

    constructor(private world: WorldService, private router: Router) { }

    public getSubRoute(path: string): string {
        if (!this.world.isLoaded()) return "";
        return "/worlds/" + this.world.id + "/" + path;
    }

    public setNavItems(topLevelRoute: string, items: NavItem[]): void {
        var newNavItems: NavItem[] = [];
        if (topLevelRoute == null) {
            var i: number = 0;
            for (let item of items) {
                newNavItems.push(new NavItem(item.title, "/" + item.route, item.icon, i++));
            }
        } else for (let entry of TOP_LEVEL_MENU) {
            newNavItems.push(new NavItem(entry.title, this.getSubRoute(entry.route), entry.icon, entry.level));
            if (entry.route == topLevelRoute) {
                var i: number = 1;
                for (let item of items) {
                    newNavItems.push(new NavItem(item.title, this.getSubRoute(item.route), item.icon, i++));
                }
            }
        }
        this.navItems = newNavItems;
    }

    public getNavItems(): NavItem[] {
        return this.navItems;
    }

    public navigate(route: string): void {
        this.router.navigate([route]);
    }
}
