import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class ApiDataEndpointService {

    constructor(private api: ApiService, private http: HttpClient) { }

    public get(resourceName: string, query: object, processor: (data: any) => void) {
        var params = new HttpParams();
        Object.keys(query).forEach((key) => { params = params.set(key, query[key]); });

        return this.http.get(this.api.getApiUrl(resourceName), { headers: this.getHeaders(false), params: params }).subscribe(
            (data: any) => {
                processor(data.data);
            },
            (error: HttpErrorResponse) => {
                this.api.processHttpError(error, () => { this.get(resourceName, query, processor); });
            }
        );
    }

    public post(resourceName: string, query: object, obj: any, processor: (data: any) => void) {
        var params = new HttpParams();
        Object.keys(query).forEach((key) => { params = params.set(key, query[key]); });

        return this.http.post(this.api.getApiUrl(resourceName), obj, { headers: this.getHeaders(false), params: params }).subscribe(
            (data: any) => {
                processor(data.data);
            },
            (error: HttpErrorResponse) => {
                this.api.processHttpError(error, () => { this.post(resourceName, query, obj, processor); });
            }
        );
    }

    private getHeaders(hasBody: boolean): HttpHeaders {
        var auth = this.api.getAuth();
        var headers: HttpHeaders = new HttpHeaders().set("Authorization", "Basic " + (auth ? auth.token : ""));
        if (hasBody) headers = headers.set("Content-Type", "application/json");
        return headers;
    }
}
