import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute } from '@angular/router';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { BigTableColumns, CalendarDate } from 'src/app/logic/utils';
import { MatSort, MatTableDataSource, MatDialog, MatPaginator } from '@angular/material';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { BasePage } from '../base-page';
import { Calendar } from 'src/app/logic/base';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html'
})
export class EventsComponent extends BasePage implements OnInit {
    @ViewChild('eventsSort', { static: true }) eventsSort: MatSort;
    @ViewChild('eventsPaginator', { static: true }) eventsPaginator: MatPaginator;
    eventsCols: BigTableColumns = new BigTableColumns([], ['startDay', 'endDay']);
    events = new MatTableDataSource([]);

    calendar: Calendar;

    constructor(
        public forge: ForgeService,
        public nav: ForgeNavService,
        public world: WorldService,
        private modelService: ApiModelService,
        private route: ActivatedRoute,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.nav.setNavItems("events", []);
            this.getCalendar();
            this.getEvents();
        });
        this.events.sort = this.eventsSort;
        this.events.paginator = this.eventsPaginator;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.eventsCols.update();
    }

    deleteEvent(event: any): void {
        this.confirm("This action is not reversible.", "Delete '" + event.name + "'?").subscribe((result) => {
            result && this.modelService.delete("events", event.id, () => this.getEvents());
        });
    }

    dateString(days: number) {
        if (!this.calendar) return days;
        return new CalendarDate(days, this.calendar).toString();
    }

    private getCalendar(): void {
        if (!this.world.calendarId) return;
        this.modelService.get("calendars", this.world.calendarId, data => {
            this.calendar = data;
            this.calendar.daysInYear = new CalendarDate(null, this.calendar).getDaysInYear();
        });
    }

    private getEvents(): void {
        this.modelService.getAll(
            "events",
            { worldId: this.world.id },
            data => {
                let displayedColumns = ['title', 'startDay', 'endDay', 'actions'];
                this.eventsCols.columns = displayedColumns;
                this.eventsCols.update();
                this.events.data = data;
            }
        );
    }
}
