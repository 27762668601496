import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'forge-question',
  templateUrl: './forge-question.component.html'
})
export class ForgeQuestionComponent {
  @Input() question: any;
  @Input() readonly: boolean;
  @Input() form: FormGroup;
  get isValid() { return this.form.controls["question" + this.question.id].valid; }
}