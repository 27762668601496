import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-involvement',
    templateUrl: './involvement.component.html'
})
export class InvolvementComponent {
    loadingSub: Subscription;

    availableCharacters = [];
    availableEvents = [];
    title: string;

    involvementForm = new FormGroup({
        characterId: new FormControl(-1, [Validators.required]),
        eventId: new FormControl(-1, [Validators.required]),
        role: new FormControl("", [])
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<InvolvementComponent>
    ) {
        this.involvementForm.setValue({
            characterId: this.data.character ? this.data.character.id : -1,
            eventId: this.data.event ? this.data.event.id : -1,
            role: this.data.role
        });
        if (data.characters) {
            this.title = "Involvement: " + data.event.title;
            for (let character of this.data.characters) {
                let found: boolean = false;
                for (let involvement of data.involvements) {
                    if (involvement.character.id == character.id) {
                        found = true;
                        break;
                    }
                }
                if (!found) this.availableCharacters.push(character);
            }
        } else if (data.events) {
            this.title = "Involvement: " + data.character.name;
            for (let event of this.data.events) {
                let found: boolean = false;
                for (let involvement of data.involvements) {
                    if (involvement.event.id == event.id) {
                        found = true;
                        break;
                    }
                }
                if (!found) this.availableEvents.push(event);
            }
        } else  {
            this.title = data.character.name + " (" + data.event.title + ")";
        }
    }

    onSubmit() {
        var value = this.involvementForm.value;
        var body = {
            character: {
                id: value.characterId
            },
            event: {
                id: value.eventId
            },
            role: value.role
        };
        this.dialog.close(body);
    }
}
