import { Component, OnInit } from '@angular/core';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { NavItem } from 'src/app/logic/base';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BasePage } from '../base-page';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConditionComponent } from 'src/app/dialogs/condition/condition.component';

@Component({
    selector: 'app-questions',
    templateUrl: './questions.component.html',
    styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent extends BasePage implements OnInit {
    beta: any;
    chapter: any;
    questions = [];
    signupQuestions = [];
    eligibilityOptions = false;

    questionForm = new FormGroup({
        text: new FormControl("", [Validators.required]),
        type: new FormControl("YESNO", [Validators.required]),
        required: new FormControl(false, [Validators.required])
    });
    questionFormSetup = {
        conditions: []
    };

    constructor(
        public forge: ForgeService,
        public nav: ForgeNavService,
        public world: WorldService,
        private modelService: ApiModelService,
        private route: ActivatedRoute,
        private router: Router,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getBeta();
        });
    }

    questionDrop(event: CdkDragDrop<{ title: string, poster: string }[]>) {
        moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
        let i = 0;
        for (let question of this.questions) {
            question.ordinal = ++i;
            let body = {
                id: question.id,
                ordinal: question.ordinal
            };
            this.modelService.update("questions", body, data => {});
        }
    }

    addQuestion(): void {
        let value = this.questionForm.value;
        let body = {
            worldId: this.world.id,
            betaId: this.beta.id,
            chapterId: this.chapter.id == "general" ? null : this.chapter.id,
            text: value.text,
            type: value.type,
            ordinal: this.forge.getTimestamp(),
            setup: {
                required: value.required,
                conditions: this.questionFormSetup.conditions
            }
        };
        this.modelService.create("questions", body, data => this.getChapter());
    }

    updateQuestion(question: any): void {
        this.modelService.update("questions", question, data => this.getChapter());
    }

    deleteQuestion(question: any): void {
        this.confirm("This action is not reversible.", "Delete Question").subscribe(
            result => result && this.modelService.delete("questions", question.id, () => this.getChapter())
        );
    }

    addCondition(setup: any) {
        this.dialog.open(ConditionComponent, {
            data: {
                questions: this.signupQuestions
            }
        }).afterClosed().subscribe(condition => {
            if (!condition) return;
            let conditions = [];
            for (let item of setup.conditions) conditions.push(item);
            conditions.push(condition);
            setup.conditions = conditions;
        });
    }

    removeCondition(setup: any, index: number) {
        let conditions = [];
        for (let item of setup.conditions) conditions.push(item);
        conditions.splice(index, 1);
        setup.conditions = conditions;
    }

    private getBeta(): void {
        let betaId = this.route.snapshot.paramMap.get("betaId");
        this.modelService.get("betas", betaId, data => {
            this.beta = data;
            this.getChapter();
        });
    }

    private getChapter(): void {
        let chapterId = this.route.snapshot.paramMap.get("chapterId");
        if (chapterId == "general") {
            this.chapter = {
                id: chapterId,
                title: "Sign-up Questions"
            };
            this.nav.setNavItems("betas", [
                new NavItem(this.beta.name, "betas/" + this.beta.id),
                new NavItem(this.chapter.title, "questions/" + this.beta.id + "/" + this.chapter.id),
            ]);
            this.getQuestions("null");
        } else {
            this.modelService.get("chapters", chapterId, data => {
                this.chapter = data;
                this.nav.setNavItems("betas", [
                    new NavItem(this.beta.name, "betas/" + this.beta.id),
                    new NavItem(this.chapter.title, "questions/" + this.beta.id + "/" + this.chapter.id),
                ]);
                this.getQuestions(chapterId);
            });
        }
    }

    private getQuestions(chapterId: any): void {
        let betaId = this.route.snapshot.paramMap.get("betaId");

        this.modelService.getAll(
            "questions",
            {
                betaId: betaId,
                chapterId: chapterId
            },
            data => {
                this.questions = data;
                this.getSignupQuestions();
            }
        );
    }

    private getSignupQuestions(): void {
        this.modelService.getAll("questions", { betaId: this.beta.id, chapterId: "null", type: 'YESNO' }, data => {
            let signupQuestions = [];
            for (let question of data) {
                signupQuestions[question.id] = question;
            }
            this.signupQuestions = signupQuestions;
        });
    }
}
