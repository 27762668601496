import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { NavItem, Calendar } from 'src/app/logic/base';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { ApiRelationService } from 'src/app/logic/services/api-relation.service';
import { BasePage } from '../base-page';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { CalendarDate, BigTableColumns } from 'src/app/logic/utils';
import { InvolvementComponent } from 'src/app/dialogs/involvement/involvement.component';
import { ResidencyComponent } from 'src/app/dialogs/residency/residency.component';
import { MembershipComponent } from 'src/app/dialogs/membership/membership.component';
import { RoleComponent } from 'src/app/dialogs/role/role.component';
import { RelationshipComponent } from 'src/app/dialogs/relationship/relationship.component';
import { ApiAvatarService } from 'src/app/logic/services/api-avatar.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-character',
    templateUrl: './character.component.html',
    styleUrls: ['./character.component.scss']
})
export class CharacterComponent extends BasePage implements OnInit {
    @ViewChild('avatar', { static: true }) avatar: ElementRef;

    @ViewChild('involvementsSort', { static: true }) involvementsSort: MatSort;
    @ViewChild('involvementsPaginator', { static: true }) involvementsPaginator: MatPaginator;
    involvementsCols: BigTableColumns = new BigTableColumns(["event.title", "role", "actions"], ["role"]);
    involvements = new MatTableDataSource([]);

    @ViewChild('membershipsSort', { static: true }) membershipsSort: MatSort;
    @ViewChild('membershipsPaginator', { static: true }) membershipsPaginator: MatPaginator;
    membershipsCols: BigTableColumns = new BigTableColumns(["faction.name", "role", "actions"], ["role"]);
    memberships = new MatTableDataSource([]);

    @ViewChild('relationshipsSort', { static: true }) relationshipsSort: MatSort;
    @ViewChild('relationshipsPaginator', { static: true }) relationshipsPaginator: MatPaginator;
    relationshipsCols: BigTableColumns = new BigTableColumns(["relative.name", "relative.relation", "relative.nickname", "actions"], ["relative.relation", "relative.nickname"]);
    relationships = new MatTableDataSource([]);

    @ViewChild('residenciesSort', { static: true }) residenciesSort: MatSort;
    @ViewChild('residenciesPaginator', { static: true }) residenciesPaginator: MatPaginator;
    residenciesCols: BigTableColumns = new BigTableColumns(["place.name", "startDay", "endDay", "actions"], ["startDay", "endDay"]);
    residencies = new MatTableDataSource([]);

    @ViewChild('rolesSort', { static: true }) rolesSort: MatSort;
    @ViewChild('residenciesPaginator', { static: true }) rolesPaginator: MatPaginator;
    rolesCols: BigTableColumns = new BigTableColumns(["book.title", "description", "priority", "actions"], ["description", "priority"]);
    roles = new MatTableDataSource([]);

    calendar: Calendar = null;
    idParam: any;
    isExisting: boolean = false;
    fileDraggingOver = false;

    additionalForm = new FormGroup({
        additional: new FormControl('', [Validators.required])
    });
    additionals = new Map<string, any>();

    physicForm = new FormGroup({
        physic: new FormControl('', [Validators.required])
    });
    physics = new Map<string, any>();

    characterForm = new FormGroup({
        name: new FormControl("", [Validators.required]),
        role: new FormControl("", [Validators.required]),
        birthDay: new FormControl(null, []),
        deathPlaceId: new FormControl(null, []),
        deathDay: new FormControl(null, []),
        birthPlaceId: new FormControl(null, []),
        personality: new FormControl("", []),
        habits: new FormControl("", []),
        background: new FormControl("", []),
        internalConflicts: new FormControl("", []),
        externalConflicts: new FormControl("", []),
        notes: new FormControl("", [])
    }, (fg: FormGroup) => {
        const start = fg.get('birthDay').value;
        const end = fg.get('deathDay').value;
        if (!start || !end) return null;
        return start <= end ? null : { rangeValidator: "Death date cannot be before birth date." };
    });

    idParamSubscription: Subscription;

    constructor(
        private world: WorldService,
        private avatarService: ApiAvatarService,
        private modelService: ApiModelService,
        private relationService: ApiRelationService,
        private route: ActivatedRoute,
        private router: Router,
        public forge: ForgeService,
        public nav: ForgeNavService,
        dialog: MatDialog
    ) {
        super(dialog);
        this.involvements.sortingDataAccessor = (item, property) => {
            switch (property) {
                case "event.title": return item.event.title;
                default: return item[property];
            }
        };
        this.memberships.sortingDataAccessor = (item, property) => {
            switch (property) {
                case "faction.name": return item.faction.name;
                default: return item[property];
            }
        };
        this.relationships.sortingDataAccessor = (item, property) => {
            switch (property) {
                case "relative.name": return item.relative.name;
                case "relative.relation": return item.relative.relation;
                case "relative.nickname": return item.relative.nickname;
                default: return item[property];
            }
        };
        this.residencies.sortingDataAccessor = (item, property) => {
            switch (property) {
                case "place.name": return item.place.name;
                default: return item[property];
            }
        };
        this.roles.sortingDataAccessor = (item, property) => {
            switch (property) {
                case "book.title": return item.book.title;
                default: return item[property];
            }
        };
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getCalendar();
            this.getCharacter();
        });
        this.involvements.sort = this.involvementsSort;
        this.involvements.paginator = this.involvementsPaginator;
        this.memberships.sort = this.membershipsSort;
        this.memberships.paginator = this.membershipsPaginator;
        this.relationships.sort = this.relationshipsSort;
        this.relationships.paginator = this.relationshipsPaginator;
        this.residencies.sort = this.residenciesSort;
        this.residencies.paginator = this.residenciesPaginator;
        this.roles.sort = this.rolesSort;
        this.roles.paginator = this.rolesPaginator;
        this.idParamSubscription = this.route.paramMap.subscribe(data => this.getCharacter() );
    }

    @HostListener('window:resize', ['$event'])
    onResize(e) {
        this.involvementsCols.update();
        this.membershipsCols.update();
        this.relationshipsCols.update();
        this.residenciesCols.update();
        this.rolesCols.update();
    }

    save() {
        var value = this.characterForm.value;
        var body = {
            id: this.isExisting ? parseInt(this.idParam) : null,
            worldId: this.world.id,
            name: value.name,
            role: value.role,
            birthDay: value.birthDay,
            deathPlaceId: value.birthPlaceId,
            deathDay: value.deathDay,
            birthPlaceId: value.deathPlaceId,
            personality: value.personality,
            habits: value.habits,
            background: value.background,
            internalConflicts: value.internalConflicts,
            externalConflicts: value.externalConflicts,
            notes: value.notes,
            additional: this.forge.mapModelToObject(this.additionals),
            physics: this.forge.mapModelToObject(this.physics)
        };

        if (this.isExisting) {
            this.modelService.update("characters", body, data => { });
        } else {
            this.modelService.create("characters", body, data => {
                this.router.navigate([this.nav.getSubRoute("characters/" + data.id)]);
                this.ngOnInit();
            });
        }
    }

    editInvolvement(involvement: any): void {
        if (involvement == null) {
            this.modelService.getAll("events", { worldId: this.world.id }, (data: any) => {
                this.dialog.open(InvolvementComponent, {
                    data: {
                        character: { id: parseInt(this.idParam), name: this.characterForm.value.name },
                        event: null,
                        characters: null,
                        events: data,
                        role: "",
                        involvements: this.involvements.data
                    }
                }).afterClosed().subscribe(body => {
                    if (body) {
                        body.worldId = this.world.id;
                        this.relationService.create("involvements", body, (data: any) => { this.getInvolvements(); });
                    }
                });
            });
        } else {
            this.dialog.open(InvolvementComponent, {
                data: {
                    character: involvement.character,
                    event: involvement.event,
                    characters: null,
                    events: null,
                    role: involvement.role,
                    involvements: this.involvements.data
                }
            }).afterClosed().subscribe(body => {
                if (body) this.relationService.update("involvements", body.character.id, body.event.id, body, (data: any) => { this.getInvolvements(); });
            });
        }
    }
    deleteInvolvement(involvement: any): void {
        this.relationService.delete("involvements", this.idParam, involvement.event.id, () => { this.getInvolvements(); });
    }

    editMembership(membership: any): void {
        if (membership == null) {
            this.modelService.getAll("factions", { worldId: this.world.id }, (data: any) => {
                this.dialog.open(MembershipComponent, {
                    data: {
                        character: { id: parseInt(this.idParam), name: this.characterForm.value.name },
                        group: null,
                        characters: null,
                        groups: data,
                        role: "",
                        notes: "",
                        memberships: this.memberships.data
                    }
                }).afterClosed().subscribe(body => {
                    if (body) {
                        body.worldId = this.world.id;
                        this.relationService.create("memberships", body, (data: any) => { this.getMemberships(); });
                    }
                });
            });
        } else {
            this.dialog.open(MembershipComponent, {
                data: {
                    character: membership.character,
                    group: membership.faction,
                    characters: null,
                    groups: null,
                    role: membership.role,
                    notes: membership.notes,
                    memberships: this.memberships.data
                }
            }).afterClosed().subscribe(body => {
                if (body) this.relationService.update("memberships", body.character.id, body.faction.id, body, (data: any) => { this.getMemberships(); });
            });
        }
    }
    deleteMembership(membership: any): void {
        this.confirm("This action is not reversible.", "Delete membership?").subscribe(
            result => result && this.relationService.delete("memberships", this.idParam, membership.faction.id, () => { this.getMemberships(); })
        );
    }

    editRelationship(relationship: any): void {
        if (relationship == null) {
            this.modelService.getAll("characters", { worldId: this.world.id }, (data: any) => {
                this.dialog.open(RelationshipComponent, {
                    data: {
                        character: { id: parseInt(this.idParam) },
                        relative: null,
                        characters: data,
                        relation: "",
                        nickname: null,
                        notes: "",
                        relationships: this.relationships.data
                    }
                }).afterClosed().subscribe(body => {
                    if (body) {
                        body.worldId = this.world.id;
                        this.relationService.create("relationships", body, (data: any) => { this.getRelationships(); });
                    }
                });
            });
        } else {
            this.dialog.open(RelationshipComponent, {
                data: {
                    character: relationship.character,
                    relative: relationship.relative,
                    characters: null,
                    relation: relationship.relative.relation,
                    nickname: relationship.relative.nickname,
                    notes: relationship.notes,
                    relationships: this.relationships.data
                }
            }).afterClosed().subscribe(body => {
                if (body) this.modelService.update("relationships", body, (data: any) => { this.getRelationships(); });
            });
        }
    }
    deleteRelationship(relationship: any): void {
        this.confirm("This action is not reversible.", "Delete relationship?").subscribe(
            result => result && this.relationService.delete("relationships", relationship.character.id, relationship.relative.id, () => { this.getRelationships(); })
        );
    }

    editResidency(residency: any): void {
        if (residency == null) {
            this.modelService.getAll("places", { worldId: this.world.id }, (data: any) => {
                this.dialog.open(ResidencyComponent, {
                    data: {
                        character: { id: parseInt(this.idParam), name: this.characterForm.value.name },
                        place: null,
                        characters: null,
                        places: data,
                        startDay: null,
                        endDay: null,
                        notes: "",
                        calendar: this.calendar
                    }
                }).afterClosed().subscribe(body => {
                    if (body) {
                        body.worldId = this.world.id;
                        this.modelService.create("residencies", body, (data: any) => { this.getResidencies(); });
                    }
                });
            });
        } else {
            this.dialog.open(ResidencyComponent, {
                data: {
                    character: residency.character,
                    place: residency.place,
                    characters: null,
                    places: null,
                    id: residency.id,
                    startDay: residency.startDay,
                    endDay: residency.endDay,
                    notes: residency.notes,
                    calendar: this.calendar
                }
            }).afterClosed().subscribe(body => {
                if (body) this.modelService.update("residencies", body, (data: any) => { this.getResidencies(); });
            });
        }
    }
    deleteResidency(residency: any): void {
        this.confirm("This action is not reversible.", "Delete residency?").subscribe(
            result => result && this.modelService.delete("residencies", residency.id, () => { this.getResidencies(); })
        );
    }

    editRole(role: any): void {
        if (role == null) {
            this.modelService.getAll("books", { worldId: this.world.id }, (data: any) => {
                this.dialog.open(RoleComponent, {
                    data: {
                        character: { id: parseInt(this.idParam), name: this.characterForm.value.name },
                        book: null,
                        characters: null,
                        books: data,
                        role: "",
                        priority: null,
                        roles: this.roles.data
                    }
                }).afterClosed().subscribe(body => {
                    if (body) {
                        body.worldId = this.world.id;
                        this.modelService.create("roles", body, (data: any) => { this.getRoles(); });
                    }
                });
            });
        } else {
            this.dialog.open(RoleComponent, {
                data: {
                    character: role.character,
                    book: role.book,
                    characters: null,
                    books: null,
                    role: role.description,
                    priority: role.priority,
                    roles: this.roles.data
                }
            }).afterClosed().subscribe(body => {
                if (body) this.relationService.update("roles", body.character.id, body.book.id, body, (data: any) => { this.getRoles(); });
            });
        }
    }
    deleteRole(role: any): void {
        this.confirm(role.character.name + " will be removed from " + role.book.title + ".", "Delete role?").subscribe(
            result => result && this.relationService.delete("roles", this.idParam, role.book.id, () => { this.getRoles(); })
        );
    }

    addAdditional() {
        var value = this.additionalForm.value;
        if (!this.additionals.has(value.additional)) {
            this.additionals.set(value.additional, { value: "" });
        }
        this.additionalForm.setValue({
            additional: ""
        });
    }
    deleteAdditional(key: string) {
        this.additionals.delete(key);
    }

    addPhysic() {
        var value = this.physicForm.value;
        if (!this.physics.has(value.physic)) {
            this.physics.set(value.physic, { value: "" });
        }
        this.physicForm.setValue({
            physic: ""
        });
    }
    deletePhysic(key: string) {
        this.physics.delete(key);
    }

    dateString(days: number) {
        if (!this.calendar) return days;
        return new CalendarDate(days, this.calendar).toString();
    }

    chooseFile() {
        const fileUpload = document.getElementById('avatarUpload') as HTMLInputElement;
        fileUpload.onchange = () => {
            if (fileUpload.files.length < 1) return;
            this.avatarService.upload("character", this.idParam, fileUpload.files[0], (result) => {
                if (!result.success) this.alert("Could not upload avatar image.", "Upload Failed"); else this.refreshAvatar();
            });
        };
        fileUpload.click();
    }

    private refreshAvatar(): void {
        this.avatar.nativeElement.style = "background-image: url(" + this.avatarService.getAvatarUrl("character", this.idParam) + ")";
    }

    private getCalendar(): void {
        if (!this.world.calendarId) return;
        this.modelService.get("calendars", this.world.calendarId, data => {
            this.calendar = data;
            this.calendar.daysInYear = new CalendarDate(null, this.calendar).getDaysInYear();
        });
    }

    private getCharacter(): void {
        this.idParam = this.route.snapshot.paramMap.get("characterId");
        this.refreshAvatar();

        if (this.idParam == "new") {
            this.isExisting = false;
            this.nav.setNavItems("characters", []);
            this.characterForm.setValue({
                name: "",
                role: "",
                birthDay: null,
                deathPlaceId: null,
                deathDay: null,
                birthPlaceId: null,
                personality: "",
                habits: "",
                background: "",
                internalConflicts: "",
                externalConflicts: "",
                notes: ""
            });
            this.physics = this.forge.objectToMapModel({
                "Eyes": "",
                "Hair Color": ""
            });
            this.additionals = this.forge.objectToMapModel({});
        } else {
            this.isExisting = true;
            this.modelService.get("characters", parseInt(this.idParam), data => {
                this.nav.setNavItems("characters", [
                    new NavItem(data.name, "characters/" + data.id)
                ]);
                this.getInvolvements();
                this.getMemberships();
                this.getRelationships();
                this.getResidencies();
                this.getRoles();
                this.characterForm.setValue({
                    name: data.name,
                    role: data.role,
                    birthDay: data.birthDay,
                    deathPlaceId: data.birthPlaceId,
                    deathDay: data.deathDay,
                    birthPlaceId: data.deathPlaceId,
                    personality: data.personality,
                    habits: data.habits,
                    background: data.background,
                    internalConflicts: data.internalConflicts,
                    externalConflicts: data.externalConflicts,
                    notes: data.notes
                });
                this.physics = this.forge.objectToMapModel(data.physics);
                this.additionals = this.forge.objectToMapModel(data.additional);
            });
        }
    }

    private getInvolvements(): void {
        let id = parseInt(this.idParam);
        this.relationService.getAll(
            "involvements",
            { characterId: id },
            data => {
                this.involvements.data = data;
            }
        );
    }

    private getMemberships(): void {
        let id = parseInt(this.idParam);
        this.relationService.getAll(
            "memberships",
            { characterId: id },
            data => {
                this.memberships.data = data;
            }
        );
    }

    private getRelationships(): void {
        let id = parseInt(this.idParam);
        this.relationService.getAll(
            "relationships",
            { characterId: id },
            data => {
                this.relationships.data = data;
            }
        );
    }

    private getResidencies(): void {
        let id = parseInt(this.idParam);
        this.modelService.getAll(
            "residencies",
            { characterId: id },
            data => {
                this.residencies.data = data;
            }
        );
    }

    private getRoles(): void {
        let id = parseInt(this.idParam);
        this.relationService.getAll(
            "roles",
            { characterId: id },
            data => {
                this.roles.data = data;
            }
        );
    }
}
