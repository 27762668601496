import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { WorldService } from 'src/app/logic/services/world.service';

@Component({
    selector: 'app-membership',
    templateUrl: './membership.component.html'
})
export class MembershipComponent {
    loadingSub: Subscription;

    membershipForm = new FormGroup({
        characterId: new FormControl(null, [Validators.required]),
        groupId: new FormControl(null, [Validators.required]),
        role: new FormControl("", []),
        notes: new FormControl("", []),
    });

    availableCharacters = [];
    availableGroups = [];
    title: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public forge: ForgeService,
        public world: WorldService,
        public dialog: MatDialogRef<MembershipComponent>
    ) {
        this.membershipForm.setValue({
            characterId: this.data.character ? this.data.character.id : null,
            groupId: this.data.group ? this.data.group.id : null,
            role: this.data.role,
            notes: this.data.notes
        });
        if (data.characters) {
            this.title = "Membership: " + data.group.name;
            for (let character of data.characters) {
                let found: boolean = false;
                for (let membership of data.memberships) {
                    if (membership.character.id == character.id) {
                        found = true;
                        break;
                    }
                }
                if (!found) this.availableCharacters.push(character);
            }
        } else if (data.groups) {
            this.title = "Membership: " + data.character.name;
            for (let group of data.groups) {
                let found: boolean = false;
                for (let membership of data.memberships) {
                    if (membership.faction.id == group.id) {
                        found = true;
                        break;
                    }
                }
                if (!found) this.availableGroups.push(group);
            }
        } else {
            this.title = data.character.name + " (" + data.group.name + ")";
        }
    }

    onSubmit() {
        var value = this.membershipForm.value;
        var body = {
            worldId: this.world.id,
            character: {
                id: value.characterId
            },
            faction: {
                id: value.groupId
            },
            role: value.role,
            notes: value.notes
        };
        this.dialog.close(body);
    }
}
