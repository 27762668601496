import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { NavItem, Calendar } from 'src/app/logic/base';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { ApiRelationService } from 'src/app/logic/services/api-relation.service';
import { BasePage } from '../base-page';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { CalendarDate, BigTableColumns } from 'src/app/logic/utils';
import { ReadingComponent } from 'src/app/dialogs/reading/reading.component';
import { ApiAvatarService } from 'src/app/logic/services/api-avatar.service';

@Component({
    selector: 'app-reader',
    templateUrl: './reader.component.html',
    styleUrls: ['./reader.component.scss']
})
export class ReaderComponent extends BasePage implements OnInit {
    @ViewChild('avatar', { static: true }) avatar: ElementRef;

    @ViewChild('readingsSort', { static: true }) readingsSort: MatSort;
    @ViewChild('readingsPaginator', { static: true }) readingsPaginator: MatPaginator;
    readingsCols: BigTableColumns = new BigTableColumns(["beta.name", "platform", "actions"], ["platform"]);
    readings = new MatTableDataSource([]);

    calendar: Calendar = null;
    reader: any = null;
    idParam: any;
    isExisting: boolean = false;
    fileDraggingOver = false;

    additionalForm = new FormGroup({
        additional: new FormControl('', [Validators.required])
    });
    additionals = new Map<string, any>();

    readerForm = new FormGroup({
        name: new FormControl("", [Validators.required]),
        email: new FormControl("", [Validators.required])
    });

    constructor(
        public forge: ForgeService,
        public nav: ForgeNavService,
        public world: WorldService,
        private avatarService: ApiAvatarService,
        private modelService: ApiModelService,
        private relationService: ApiRelationService,
        private route: ActivatedRoute,
        private router: Router,
        dialog: MatDialog
    ) {
        super(dialog);
        this.readings.sortingDataAccessor = (item, property) => {
            switch (property) {
                case "beta.name": return item.beta.name;
                default: return item[property];
            }
        };
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getCalendar();
            this.getReader();
        });
        this.readings.sort = this.readingsSort;
        this.readings.paginator = this.readingsPaginator;
    }

    @HostListener('window:resize', ['$event'])
    onResize(e) {
        this.readingsCols.update();
    }

    save() {
        var value = this.readerForm.value;
        var body = {
            id: this.isExisting ? parseInt(this.idParam) : null,
            worldId: this.world.id,
            name: value.name,
            email: value.email,
            additional: this.forge.mapModelToObject(this.additionals)
        };

        if (this.isExisting) {
            this.modelService.update("readers", body, data => { });
        } else {
            this.modelService.create("readers", body, data => {
                this.router.navigate([this.nav.getSubRoute("readers/" + data.id)]);
                this.ngOnInit();
            });
        }
    }

    editReading(reading: any): void {
        if (reading == null) {
            this.modelService.getAll("betas", { worldId: this.world.id }, (data: any) => {
                this.dialog.open(ReadingComponent, {
                    data: {
                        betaId: null,
                        readerId: parseInt(this.idParam),
                        betas: data,
                        readers: null,
                        platform: "UNKNOWN"
                    }
                }).afterClosed().subscribe(body => {
                    if (body) {
                        body.worldId = this.world.id;
                        this.modelService.create("readings", body, (data: any) => { this.getReadings(); });
                    }
                });
            });
        } else {
            this.dialog.open(ReadingComponent, {
                data: {
                    betaId: reading.beta.id,
                    readerId: reading.reader.id,
                    betas: null,
                    readers: null,
                    platform: reading.platform
                }
            }).afterClosed().subscribe(body => {
                if (body) this.relationService.update("readings", body.beta.id, body.reader.id, body, (data: any) => { this.getReadings(); });
            });
        }
    }
    deleteReading(reading: any): void {
        this.confirm("Reader's '" + reading.beta.name + "' feedback will be deleted.", "Unassign '" + reading.reader.name + "'?").subscribe(
            result => result && this.relationService.delete("readings", reading.beta.id, this.idParam, () => { this.getReadings(); })
        );
    }

    addAdditional() {
        var value = this.additionalForm.value;
        if (!this.additionals.has(value.additional)) {
            this.additionals.set(value.additional, { value: "" });
        }
        this.additionalForm.setValue({
            additional: ""
        });
    }
    deleteAdditional(key: string) {
        this.additionals.delete(key);
    }

    dateString(days: number) {
        if (!this.calendar) return days;
        return new CalendarDate(days, this.calendar).toString();
    }

    chooseFile() {
        const fileUpload = document.getElementById('avatarUpload') as HTMLInputElement;
        fileUpload.onchange = () => {
            if (fileUpload.files.length < 1) return;
            this.avatarService.upload("reader", this.idParam, fileUpload.files[0], (result) => {
                if (!result.success) this.alert("Could not upload avatar image.", "Upload Failed"); else this.refreshAvatar();
            });
        };
        fileUpload.click();
    }

    private refreshAvatar(): void {
        this.avatar.nativeElement.style = "background-image: url(" + this.avatarService.getAvatarUrl("reader", this.idParam) + ")";
    }

    private getCalendar(): void {
        if (!this.world.calendarId) return;
        this.modelService.get("calendars", this.world.calendarId, data => {
            this.calendar = data;
            this.calendar.daysInYear = new CalendarDate(null, this.calendar).getDaysInYear();
        });
    }

    private getReader(): void {
        this.idParam = this.route.snapshot.paramMap.get("readerId");
        this.refreshAvatar();

        if (this.idParam == "new") {
            this.isExisting = false;
            this.nav.setNavItems("readers", []);
            this.readerForm.setValue({
                name: "",
                email: ""
            });
            this.additionals = this.forge.objectToMapModel({});
        } else {
            this.isExisting = true;
            this.modelService.get("readers", parseInt(this.idParam), data => {
                this.nav.setNavItems("readers", [
                    new NavItem(data.name, "readers/" + data.id)
                ]);
                this.getReadings();
                this.readerForm.setValue({
                    name: data.name,
                    email: data.email
                });
                this.additionals = this.forge.objectToMapModel(data.additional);
            });
        }
    }

    private getReadings(): void {
        let id = parseInt(this.idParam);
        this.relationService.getAll(
            "readings",
            { readerId: id },
            data => {
                this.readings.data = data;
                this.readingsCols.update();
            }
        );
    }
}
