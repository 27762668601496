import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute } from '@angular/router';
import { BigTableColumns } from 'src/app/logic/utils';
import { MatSort, MatTableDataSource, MatDialog, MatPaginator } from '@angular/material';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { BasePage } from '../base-page';

@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html'
})
export class GroupsComponent extends BasePage implements OnInit {
    @ViewChild('groupsSort', { static: true }) groupsSort: MatSort;
    @ViewChild('groupsPaginator', { static: true }) groupsPaginator: MatPaginator;
    groupsCols: BigTableColumns = new BigTableColumns([], ['population']);
    groups = new MatTableDataSource([]);

    constructor(
        public nav: ForgeNavService,
        public world: WorldService,
        private modelService: ApiModelService,
        private route: ActivatedRoute,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.nav.setNavItems("groups", []);
            this.getGroups();
        });
        this.groups.sort = this.groupsSort;
        this.groups.paginator = this.groupsPaginator;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.groupsCols.update();
    }

    deleteGroup(group: any): void {
        this.confirm("This action is not reversible.", "Delete '" + group.name + "'?").subscribe((result) => {
            result && this.modelService.delete("factions", group.id, () => this.getGroups());
        });
    }

    private getGroups(): void {
        this.modelService.getAll(
            "factions",
            { worldId: this.world.id },
            data => {
                let displayedColumns = ['name', 'population', 'actions'];
                this.groupsCols.columns = displayedColumns;
                this.groupsCols.update();
                this.groups.data = data;
            }
        );
    }
}
