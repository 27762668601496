import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatAutocompleteSelectedEvent, MatInput } from '@angular/material';
import { NavItem } from 'src/app/logic/base';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BasePage } from '../base-page';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent extends BasePage implements OnInit {
    @ViewChild('placeInput', { static: true }) placeInput: MatInput;
    newPlaceControl = new FormControl("");

    calendarForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        epoch: new FormControl('', [Validators.required, Validators.pattern("[0-9]+")])
    });

    additionalForm = new FormGroup({
        additional: new FormControl('', [Validators.required])
    });
    additionals = new Map<string, any>();

    calendarPlaceIds: number[] = [];
    otherPlaceIds: number[] = [];
    filteredOtherPlaceIds: Observable<number[]>;
    places = [];
    expanded = [];

    desc = {
        months: [],
        holidays: []
    };
    idParam: any;
    isExisting: boolean = false;

    constructor(
        private forge: ForgeService,
        private world: WorldService,
        private modelService: ApiModelService,
        private route: ActivatedRoute,
        private router: Router,
        public nav: ForgeNavService,
        dialog: MatDialog
    ) {
        super(dialog);
        this.nav = nav;
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getCalendar();
            this.getPlaces();
        });
        this.filteredOtherPlaceIds = this.newPlaceControl.valueChanges.pipe(
            startWith(""),
            map(value => this._filter(value))
        );
    }

    private _filter(value: string): number[] {
        const filterValue = value ? value.toLowerCase() : "";
        return this.otherPlaceIds.filter(option => this.places[option] ? this.places[option].name.toLowerCase().includes(filterValue) : false);
    }

    save() {
        for (let i = 0; i < this.desc.months.length; i++) {
            let month = this.desc.months[i];
            if (month.name == "") {
                this.alert("Month at position " + (i + 1) + " has no name.", "Save Calendar");
                return;
            }
            if (month.days == "") {
                this.alert("Month '" + month.name + "' does not have the number of days set.", "Save Calendar");
                return;
            }
            let days: number = parseInt(month.days);
            if (isNaN(days)) {
                this.alert("Days set for month '" + month.name + "' is not a number.", "Save Calendar");
                return;
            }
            month.days = days;
        }
        var value = this.calendarForm.value;
        var additional = this.forge.mapModelToObject(this.additionals);
        additional.__desc__ = this.desc;
        var body = {
            id: this.isExisting ? parseInt(this.idParam) : null,
            worldId: this.world.id,
            name: value.name,
            epoch: value.epoch,
            placeIds: this.calendarPlaceIds,
            additional: additional
        };

        if (this.isExisting) {
            this.modelService.update("calendars", body, data => { });
        } else {
            this.modelService.create("calendars", body, data => {
                this.router.navigate([this.nav.getSubRoute("calendars/" + data.id)]);
                this.ngOnInit();
            });
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        for (let item of this.places) {
            if (item && item.name == event.option.viewValue) {
                this.addPlace(item.id);
                break;
            }
        }
        this.newPlaceControl.setValue("");
        (<any>this.placeInput).nativeElement.value = "";
    }

    addPlace(id: number): void {
        let i: number = this.otherPlaceIds.indexOf(id);
        if (i < 0) return;
        this.calendarPlaceIds.push(id);
        this.otherPlaceIds.splice(i, 1);
    }

    removePlace(id: number): void {
        let i: number = this.calendarPlaceIds.indexOf(id);
        if (i < 0) return;
        this.otherPlaceIds.push(id);
        this.calendarPlaceIds.splice(i, 1);
    }

    addAdditional() {
        var value = this.additionalForm.value;
        if (!this.additionals.has(value.additional)) {
            this.additionals.set(value.additional, { value: "" });
        }
        this.additionalForm.setValue({
            additional: ""
        });
    }
    deleteAdditional(key: string) {
        this.additionals.delete(key);
    }

    addMonth(): void {
        this.desc.months.push({
            name: "New Month",
            days: 30
        });
    }

    deleteMonth(index: any): void {
        var month = this.desc.months[index];
        this.confirm("This will affect the number of days in the year.", "Delete '" + month.name + "'?").subscribe(
            result => result && this.desc.months.splice(index, 1)
        );
    }

    monthDrop(event: CdkDragDrop<{ title: string, poster: string }[]>) {
        moveItemInArray(this.desc.months, event.previousIndex, event.currentIndex);
    }

    private getCalendar(): void {
        this.idParam = this.route.snapshot.paramMap.get("calendarId");

        if (this.idParam == "new") {
            this.isExisting = false;
            this.nav.setNavItems(null, []);
            this.calendarForm.setValue({
                name: '',
                epoch: ''
            });
            this.desc = {
                months: [],
                holidays: []
            };
            this.additionals = this.forge.objectToMapModel({});
            this.calendarPlaceIds = [];
        } else {
            this.isExisting = true;
            this.modelService.get("calendars", parseInt(this.idParam), data => {
                this.nav.setNavItems("calendars", [
                    new NavItem(data.name, "calendars/" + data.id)
                ]);
                this.calendarForm.setValue({
                    name: data.name,
                    epoch: data.epoch
                });
                this.desc = data.additional.__desc__;
                delete data.additional.__desc__;
                this.additionals = this.forge.objectToMapModel(data.additional);
                this.calendarPlaceIds = data.placeIds;
            });
        }
    }

    private getPlaces(): void {
        this.modelService.getAll(
            "places",
            { worldId: this.world.id },
            data => {
                let otherPlaceIds = [];
                let places = [];
                for (let item of data) {
                    if (this.calendarPlaceIds.indexOf(item.id) < 0) otherPlaceIds.push(item.id);
                    places[item.id] = item;
                }
                this.places = places;
                this.otherPlaceIds = otherPlaceIds;
            }
        );
    }
}
