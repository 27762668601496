import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute } from '@angular/router';
import { BigTableColumns } from 'src/app/logic/utils';
import { MatSort, MatTableDataSource, MatDialog, MatPaginator } from '@angular/material';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { BasePage } from '../base-page';

@Component({
    selector: 'app-characters',
    templateUrl: './characters.component.html'
})
export class CharactersComponent extends BasePage implements OnInit {
    @ViewChild('charactersSort', { static: true }) charactersSort: MatSort;
    @ViewChild('charactersPaginator', { static: true }) charactersPaginator: MatPaginator;
    charactersCols: BigTableColumns = new BigTableColumns([], ['role']);
    characters = new MatTableDataSource([]);

    constructor(
        public nav: ForgeNavService,
        public world: WorldService,
        private modelService: ApiModelService,
        private route: ActivatedRoute,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.nav.setNavItems("characters", []);
            this.getCharacters();
        });
        this.characters.sort = this.charactersSort;
        this.characters.paginator = this.charactersPaginator;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.charactersCols.update();
    }

    deleteCharacter(character: any): void {
        this.confirm("This action is not reversible.", "Delete '" + character.name + "'?").subscribe((result) => {
            result && this.modelService.delete("characters", character.id, () => this.getCharacters());
        });
    }

    private getCharacters(): void {
        this.modelService.getAll(
            "characters",
            { worldId: this.world.id },
            data => {
                let displayedColumns = ['name', 'role', 'actions'];
                this.charactersCols.columns = displayedColumns;
                this.charactersCols.update();
                this.characters.data = data;
            }
        );
    }
}
