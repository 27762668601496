import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class ApiRelationService {

    constructor(private api: ApiService, private http: HttpClient) { }

    public get(resourceName: string, id1: number, id2: number, processor: (data: any) => void) {
        return this.http.get(this.api.getApiUrl(resourceName) + "/" + id1 + "-" + id2, { headers: this.getHeaders(false) }).subscribe(
            (data: any) => {
                processor(data.data);
            },
            (error: HttpErrorResponse) => {
                this.api.processHttpError(error, () => { this.get(resourceName, id1, id2, processor); });
            }
        );
    }

    public getAll(resourceName: string, query: object, processor: (data: any) => void) {
        var params = new HttpParams();
        Object.keys(query).forEach((key) => { params = params.set(key, query[key]); });

        return this.http.get(this.api.getApiUrl(resourceName), { headers: this.getHeaders(false), params: params }).subscribe(
            (data: any) => {
                processor(data.data);
            },
            (error: HttpErrorResponse) => {
                this.api.processHttpError(error, () => { this.getAll(resourceName, query, processor); });
            }
        );
    }

    public create(resourceName: string, obj: any, processor: (data: any) => void) {
        return this.http.post(this.api.getApiUrl(resourceName), obj, { headers: this.getHeaders(true)}).subscribe(
            (data: any) => {
                processor(data.data);
            },
            (error: HttpErrorResponse) => {
                this.api.processHttpError(error, () => { this.create(resourceName, obj, processor); });
            }
        );
    }

    public update(resourceName: string, id1: number, id2: number, obj: any, processor: (data: any) => void) {
        return this.http.post(this.api.getApiUrl(resourceName) + "/" + id1 + "-" + id2, obj, { headers: this.getHeaders(true)}).subscribe(
            (data: any) => {
                processor(data.data);
            },
            (error: HttpErrorResponse) => {
                this.api.processHttpError(error, () => { this.update(resourceName, id1, id2, obj, processor); });
            }
        );
    }

    public delete(resourceName: string, id1: number, id2: number, callback: () => void) {
        return this.http.delete(this.api.getApiUrl(resourceName) + "/" + id1 + "-" + id2, { headers: this.getHeaders(false) }).subscribe(
            (data: any) => {
                callback();
            },
            (error: HttpErrorResponse) => {
                this.api.processHttpError(error, () => { this.delete(resourceName, id1, id2, callback); });
            }
        );
    }

    private getHeaders(hasBody: boolean): HttpHeaders {
        var auth = this.api.getAuth();
        var headers: HttpHeaders = new HttpHeaders().set("Authorization", "Basic " + (auth ? auth.token : ""));
        if (hasBody) headers = headers.set("Content-Type", "application/json");
        return headers;
    }
}
