import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-condition',
    templateUrl: './condition.component.html',
    styleUrls: ['./condition.component.scss']
})
export class ConditionComponent {
    conditionForm = new FormGroup({
        questionId: new FormControl(null, [Validators.required]),
        answer: new FormControl("", [Validators.required]),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<ConditionComponent>
    ) { }

    onSubmit() {
        var value = this.conditionForm.value;
        this.dialog.close({
            questionId: value.questionId,
            answer: value.answer
        });
    }
}
