import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class ApiAvatarService {

    private cachebuster: string;

    constructor(private api: ApiService, private http: HttpClient) {
        this.refershCacheBuster();
    }

    public getAvatarUrl(type: string, id: any) {
        return this.api.getApiUrl("avatar") + "/?ot=" + type + "&id=" + id + "&cb=" + this.cachebuster;
    }

    public get(type: string, id: number, processor: (data: any) => void) {
        return this.http.get(this.getAvatarUrl(type, id), { headers: this.getHeaders(null) }).subscribe(
            (data: any) => {
                processor(data.data);
            },
            (error: HttpErrorResponse) => {
                this.api.processHttpError(error, () => { this.get(type, id, processor); });
            }
        );
    }

    public upload(type: string, id: any, file: File, processor: (data: any) => void) {
        return this.http.post(this.getAvatarUrl(type, id), file, { headers: this.getHeaders(file) }).subscribe(
            (data: any) => {
                this.refershCacheBuster();
                processor(data);
            },
            (error: HttpErrorResponse) => {
                this.api.processHttpError(error, () => { this.upload(type, id, file, processor); });
            }
        );
    }

    public delete(type: string, id: number, callback: () => void) {
        return this.http.delete(this.getAvatarUrl(type, id), { headers: this.getHeaders(null) }).subscribe(
            (data: any) => {
                this.refershCacheBuster();
                callback();
            },
            (error: HttpErrorResponse) => {
                this.api.processHttpError(error, () => { this.delete(type, id, callback); });
            }
        );
    }

    private getHeaders(file: File): HttpHeaders {
        var auth = this.api.getAuth();
        var headers: HttpHeaders = new HttpHeaders().set("Authorization", "Basic " + (auth ? auth.token : ""));
        if (file) {
            headers = headers.set("Content-Type", file.type);
        }
        return headers;
    }

    private refershCacheBuster(): string {
        this.cachebuster = "" + Math.round((new Date()).getTime() / 1000);
        return this.cachebuster;
    }
}
