import { Component, OnInit } from '@angular/core';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { BasePage } from '../base-page';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { WorldService } from 'src/app/logic/services/world.service';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { BetaApprovalComponent } from 'src/app/dialogs/beta-approval/beta-approval.component';
import { ApiRelationService } from 'src/app/logic/services/api-relation.service';
import { NavItem } from 'src/app/logic/base';

@Component({
    selector: 'app-beta-application',
    templateUrl: './beta-application.component.html',
    styleUrls: ['./beta-application.component.scss']
})
export class BetaApplicationComponent extends BasePage implements OnInit {
    idParam: any;
    application: any;
    beta: any;

    constructor(
        private modelService: ApiModelService,
        private relationService: ApiRelationService,
        private router: Router,
        private route: ActivatedRoute,
        public world: WorldService,
        public forge: ForgeService,
        public nav: ForgeNavService,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getApplication();
        });
    }

    getBookTitle(): string {
        let title = this.beta.book.title;
        let subtitle = this.beta.book.subtitle;
        if (subtitle && subtitle != "") title += ": " + subtitle;
        return title;
    }

    rejectApplication() {
        this.confirm("This action is not reversible.", "Reject Application?").subscribe(
            result => result && this.deleteApplication()
        );
    }

    approveApplication() {
        let additional = {
            "Book of Interest": this.getBookTitle(),
            "Age": this.application.age,
            "Gender": this.forge.genders[this.application.gender],
            "Preferred Platform": this.forge.readingPlatforms[this.application.platform],
            "General Location": this.application.location,
            "Round Preference": this.getRoundPreferenceText(this.application.roundPreference)
        };

        var reader: any = {
            worldId: this.world.id,
            type: 1,
            name: this.application.name,
            email: this.application.email,
            additional: additional
        };

        this.modelService.getAll("readers", { worldId: this.world.id }, data => {
            this.dialog.open(BetaApprovalComponent, {
                data: { readers: data, application: this.application }
            }).afterClosed().subscribe(result => {
                if (result.updateExisting) {
                    reader.id = result.readerId;
                    this.relationService.getAll("readings", {readerId: reader.id}, data => {
                        let found: boolean = false;
                        if (result.addToBeta) {
                            for (let reading of data) {
                                if (reading.beta.id == this.beta.id) {
                                    found = true;
                                    break;
                                }
                            }
                        }
                        if (found) {
                            this.alert("Selected reader is already participating in this round. Skipping participation data.", "Add to Round");
                            this.deleteApplication();
                        } else {
                            this.modelService.update("readers", reader, data => {
                                if(result.addToBeta) this.addToRound(data); else this.deleteApplication();
                            });
                        }
                    });
                } else {
                    this.modelService.create("readers", reader, data => {
                        if(result.addToBeta) this.addToRound(data); else this.deleteApplication();
                    });
                }
            });
        });
    }

    getRoundPreferenceText(pref: number) {
        var result = [];
        if ((pref & 1) > 0) result.push("the first round");
        if ((pref & 2) > 0) result.push("intermediate rounds");
        if ((pref & 4) > 0) result.push("the final round");
        return "I would like to be a part of " + result.join("/") + (result.length > 0 ? " of betas." : "");
    }

    private addToRound(reader) {
        var reading = {
            worldId: this.beta.worldId,
            beta: { id: this.beta.id },
            reader: { id: reader.id }
        };
        this.relationService.create("readings", reading, data => {
            for (let question of this.application.questions) {
                var answer = {
                    worldId: this.world.id,
                    question: { id: question.id },
                    reader: { id: reader.id },
                    text: question.answer
                };
                this.relationService.create("answers", answer, data => { });
            }
            this.deleteApplication();
        });
    }

    private deleteApplication() {
        this.modelService.delete("beta-applications", this.application.id, () => {
            this.router.navigate([this.nav.getSubRoute("betas/" + this.beta.id)]);
        });
    }

    private getApplication(): void {
        this.idParam = this.route.snapshot.paramMap.get("applicationId");
        this.modelService.get("beta-applications", this.idParam, data => {
            this.application = data;
            this.getBeta();
        });
    }

    private getBeta() {
        this.modelService.get("betas", this.application.betaId, data => {
            this.beta = data;
            this.nav.setNavItems("betas", [
                new NavItem(data.name, "betas/" + this.beta.id),
                new NavItem("Application: " + this.application.name, "beta-applications/" + this.application.id),
            ]);
        });
    }
}
