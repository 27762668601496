import { Component, OnInit } from '@angular/core';
import { ApiDataEndpointService } from 'src/app/logic/services/api-dep.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { BasePage } from '../base-page';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { NavItem } from 'src/app/logic/base';

@Component({
    selector: 'app-questionnaire-overview',
    templateUrl: './questionnaire-overview.component.html',
    styleUrls: ['./questionnaire-overview.component.scss']
})
export class QuestionnaireOverviewComponent extends BasePage implements OnInit {
    uid: string;
    questionnaire: any = {};

    constructor(
        private nav: ForgeNavService,
        private depService: ApiDataEndpointService,
        private route: ActivatedRoute,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        this.getQuestionnaire();
    }

    getBookTitle(): string {
        if (!this.questionnaire.book) return "";
        let title = this.questionnaire.book.title;
        let subtitle = this.questionnaire.book.subtitle;
        if (subtitle && subtitle != "") title += ": " + subtitle;
        return title;
    }

    private getQuestionnaire(): void {
        let params = this.route.snapshot.paramMap;
        this.uid = params.get("readingUid");
        this.depService.get("questionnaire", { reading: this.uid }, data => {
            this.questionnaire = data;
            this.nav.setNavItems(null, [
                new NavItem(data.book.title, "questionnaire/" + this.uid)
            ]);
        });
    }
}
