import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { BasePage } from '../base-page';
import { BigTableColumns } from 'src/app/logic/utils';
import { TreeModel, TreeNode, TREE_ACTIONS } from 'angular-tree-component';

@Component({
    selector: 'app-places',
    templateUrl: './places.component.html'
})
export class PlacesComponent extends BasePage implements OnInit {
    @ViewChild('placesSort', { static: true }) placesSort: MatSort;
    @ViewChild('placesPaginator', { static: true }) placesPaginator: MatPaginator;
    placesCols: BigTableColumns = new BigTableColumns([], ['area', 'population']);
    places = new MatTableDataSource([]);

    treeConfig = {
        allowDrag: true,
        actionMapping: {
            mouse: {
                drop: (tree: TreeModel, node: TreeNode, $event: any, { from, to }) => {
                    let movedNode = from.data;
                    let parent = to.parent.data;
                    let message: string = parent.virtual ? "The place will be moved to the top level." : ("The new parent will be '" + parent.name + "'.");
                    if (parent.id == movedNode.parentId) return;
                    if (this.searchForId(movedNode, parent.id)) {
                        if (movedNode.id == parent.id) return;
                        this.alert("Cannot move place to its children.", "Move Canceled");
                        return;
                    }
                    
                    this.confirm(message, "Move '" + movedNode.name + "'?").subscribe((result) => {
                        if (!result) return;
                        if (!to) return;
                        let parentId = null;
                        if (!parent.virtual) parentId = parent.id;
                        let place = {
                            id: movedNode.id,
                            parentId: parentId
                        };
                        this.modelService.update("places", place, () => { TREE_ACTIONS.MOVE_NODE(tree, node, $event, { from: from, to: to }); });
                    });
                }
            }
        }
    };
    placeTree = [];

    constructor(
        public nav: ForgeNavService,
        public world: WorldService,
        private modelService: ApiModelService,
        private route: ActivatedRoute,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.nav.setNavItems("places", []);
            this.getPlaces();
        });
        this.places.sort = this.placesSort;
        this.places.paginator = this.placesPaginator;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.placesCols.update();
    }

    deletePlace(place: any): void {
        this.confirm("This action is not reversible.", "Delete '" + place.name + "'?").subscribe((result) => {
            result && this.modelService.delete("places", place.id, () => this.getPlaces());
        });
    }

    private searchForId(node: any, id: number): boolean {
        if (node.id == id) return true;
        if (node.children.length > 0) {
            for (let child of node.children) {
                if (this.searchForId(child, id)) return true;
            }
        }
        return false;
    }
    private getPlaces(): void {
        this.modelService.getAll(
            "places",
            { worldId: this.world.id },
            data => {
                let nodesById = [];
                let placeTree = [];
                let placeNodes = [];
                for (let place of data) {
                    let node = {
                        id: place.id,
                        name: place.name,
                        parentId: place.parentId,
                        children: []
                    }
                    nodesById[node.id] = node;
                    placeNodes.push(node);
                }
                for (let place of data) {
                    let node = nodesById[place.id];
                    if (node.parentId == null) {
                        placeTree.push(node);
                    } else {
                        let parentNode = nodesById[node.parentId];
                        parentNode.children.push(node);
                    }
                }
                this.placeTree = placeTree;
                let displayedColumns = ['name', 'area', 'population', 'actions'];
                this.placesCols.columns = displayedColumns;
                this.placesCols.update();
                this.places.data = data;
            }
        );
    }
}
