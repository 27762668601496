import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { NavItem } from 'src/app/logic/base';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { ApiRelationService } from 'src/app/logic/services/api-relation.service';
import { BasePage } from '../base-page';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { ReadingComponent } from 'src/app/dialogs/reading/reading.component';
import { BigTableColumns } from 'src/app/logic/utils';

@Component({
    selector: 'app-beta',
    templateUrl: './beta.component.html'
})
export class BetaComponent extends BasePage implements OnInit {
    @ViewChild('avatar', { static: true }) avatar: ElementRef;

    @ViewChild('readingsSort', { static: true }) readingsSort: MatSort;
    @ViewChild('readingsPaginator', { static: true }) readingsPaginator: MatPaginator;
    readingsCols: BigTableColumns = new BigTableColumns(["reader.name", "platform", "actions"], ["platform"]);
    readings = new MatTableDataSource([]);

    @ViewChild('applicationsSort', { static: true }) applicationsSort: MatSort;
    @ViewChild('applicationsPaginator', { static: true }) applicationsPaginator: MatPaginator;
    applicationsCols: BigTableColumns = new BigTableColumns(["name", "email", "age", "gender", "actions"], ["email", "age", "gender"]);
    applications = new MatTableDataSource([]);
    
    chapters = new MatTableDataSource([]);

    books = [];
    idParam: any;
    checksum: string;
    isExisting: boolean = false;

    betaForm = new FormGroup({
        name: new FormControl("", [Validators.required]),
        bookId: new FormControl(null, [Validators.required]),
        startDate: new FormControl(null, [Validators.required]),
        endDate: new FormControl(null, []),
        notes: new FormControl("", []),
        signupText: new FormControl("", [])
    }, (fg: FormGroup) => {
        const start = fg.get('startDate').value;
        const end = fg.get('endDate').value;
        if (!start || !end) return null;
        return start <= end ? null : { rangeValidator: "Deadline cannot be before start date." };
    });

    constructor(
        private modelService: ApiModelService,
        private relationService: ApiRelationService,
        private route: ActivatedRoute,
        private router: Router,
        public world: WorldService,
        public forge: ForgeService,
        public nav: ForgeNavService,
        dialog: MatDialog
    ) {
        super(dialog);
        this.readings.sortingDataAccessor = (item, property) => {
            switch (property) {
                case "reader.name": return item.reader.name;
                default: return item[property];
            }
        };
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getBooks();
            this.getBeta();
        });
        this.readings.sort = this.readingsSort;
        this.readings.paginator = this.readingsPaginator;
        this.applications.sort = this.applicationsSort;
        this.applications.paginator = this.applicationsPaginator;
    }

    @HostListener('window:resize', ['$event'])
    onResize(e) {
        this.readingsCols.update();
        this.applicationsCols.update();
    }

    save() {
        let value = this.betaForm.value;
        var body = {
            id: this.isExisting ? parseInt(this.idParam) : null,
            worldId: this.world.id,
            name: value.name,
            book: { id: value.bookId },
            startDate: value.endDate ? this.forge.jsDateToSqlDate(value.startDate) : null,
            endDate: value.endDate ? this.forge.jsDateToSqlDate(value.endDate) : null,
            notes: value.notes,
            signupText: value.signupText
        };

        if (this.isExisting) {
            this.modelService.update("betas", body, data => { });
        } else {
            this.modelService.create("betas", body, data => {
                this.router.navigate([this.nav.getSubRoute("betas/" + data.id)]);
                this.ngOnInit();
            });
        }
    }

    editReading(reading: any): void {
        if (reading == null) {
            this.modelService.getAll("readers", { worldId: this.world.id }, (data: any) => {
                this.dialog.open(ReadingComponent, {
                    data: {
                        betaId: parseInt(this.idParam),
                        readerId: null,
                        betas: null,
                        readers: data,
                        platform: "UNKNOWN",
                        readings: this.readings.data
                    }
                }).afterClosed().subscribe(body => {
                    if (body) {
                        body.worldId = this.world.id;
                        this.modelService.create("readings", body, (data: any) => { this.getReadings(); });
                    }
                });
            });
        } else {
            this.dialog.open(ReadingComponent, {
                data: {
                    betaId: reading.beta.id,
                    readerId: reading.reader.id,
                    betas: null,
                    readers: null,
                    platform: reading.platform
                }
            }).afterClosed().subscribe(body => {
                if (body) this.relationService.update("readings", body.beta.id, body.reader.id, body, (data: any) => { this.getReadings(); });
            });
        }
    }
    deleteReading(reading: any): void {
        this.confirm(reading.reader.name + "'s feedback will be deleted.", "Unassign '" + reading.reader.name + "'?").subscribe(
            result => result && this.relationService.delete("readings", reading.beta.id, reading.reader.id, () => { this.getReadings(); })
        );
    }

    deleteApplication(application: any): void {
        this.confirm("This action is not reversible.", "Delete application?").subscribe(
            result => result && this.modelService.delete("applications", application.id, () => { this.getApplications(); })
        );
    }

    private getBeta(): void {
        this.idParam = this.route.snapshot.paramMap.get("betaId");

        if (this.idParam == "new") {
            this.isExisting = false;
            this.nav.setNavItems("betas", []);
            this.betaForm.setValue({
                name: "",
                bookId: null,
                startDate: null,
                endDate: null,
                notes: "",
                signupText: ""
            });
        } else {
            this.isExisting = true;
            this.modelService.get("betas", parseInt(this.idParam), data => {
                this.nav.setNavItems("betas", [
                    new NavItem(data.name, "betas/" + data.id)
                ]);
                this.getChapters(data.book.id);
                this.getReadings();
                this.getApplications();
                this.betaForm.setValue({
                    name: data.name,
                    bookId: data.book.id,
                    startDate: data.startDate ? this.forge.sqlDateToJsDate(data.startDate) : null,
                    endDate: data.endDate ? this.forge.sqlDateToJsDate(data.endDate) : null,
                    notes: data.notes,
                    signupText: data.signupText
                });
                this.checksum = data.checksum;
            });
        }
    }

    private getBooks(): void {
        this.modelService.getAll("books", { worldId: this.world.id }, data => this.books = data );
    }

    private getChapters(bookId: number): void {
        this.relationService.getAll(
            "chapters",
            { bookId: bookId },
            data => {
                let chapters = [{
                    id: "general",
                    title: "Sign-up Questions",
                    link: null
                }];
                for (let chapter of data) {
                    chapters.push({
                        id: chapter.id,
                        title: "Questions after ",
                        link: chapter.title
                    });
                }
                this.chapters.data = chapters;
            }
        );
    }

    private getReadings(): void {
        let id = parseInt(this.idParam);
        this.relationService.getAll(
            "readings",
            { betaId: id },
            data => {
                this.readings.data = data;
                this.readingsCols.update();
            }
        );
    }

    private getApplications(): void {
        let id = parseInt(this.idParam);
        this.modelService.getAll(
            "beta-applications",
            { betaId: id },
            data => {
                this.applications.data = data;
                this.applicationsCols.update();
            }
        );
    }
}
