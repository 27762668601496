import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { BasePage } from '../base-page';
import { ApiDataEndpointService } from 'src/app/logic/services/api-dep.service';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute } from '@angular/router';
import { NavItem } from 'src/app/logic/base';
import { BigTableColumns } from 'src/app/logic/utils';

@Component({
    selector: 'app-beta-report',
    templateUrl: './beta-report.component.html',
    styleUrls: ['./beta-report.component.scss']
})
export class BetaReportComponent extends BasePage implements OnInit {
    @ViewChild("chaptersPaginator", { static: true }) chaptersPaginator: MatPaginator;
    chaptersCols = new BigTableColumns(["title", "rating", "completed", "actions"], ["rating"]);
    chapters = new MatTableDataSource([]);

    @ViewChild("readersPaginator", { static: true }) readersPaginator: MatPaginator;
    readersCols = ["name", "completed"];
    readers = new MatTableDataSource([]);

    idParam: any;
    report: any;
    progress: number;
    approvedProgress: number;
    remainingDays: number;
    totalDays: number;

    constructor(
        private depService: ApiDataEndpointService,
        private modelService: ApiModelService,
        public world: WorldService,
        public forge: ForgeService,
        public nav: ForgeNavService,
        private route: ActivatedRoute,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getReport();
        });
        this.chapters.paginator = this.chaptersPaginator;
        this.readers.paginator = this.readersPaginator;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.chaptersCols.update();
    }

    getChapterProgress(entry: any): any[] {
        let total: number = this.readers.data.length;
        let result = [];
        for (let i = 0; i < total; i++) {
            let item: any = {
                unlocked: i < entry.unlocked,
                completed: i < entry.completed,
                approved: i < entry.approved,
            };
            result.push(item);
        }
        return result;
    }

    getReaderProgress(entry: any): any[] {
        let total: number = this.chapters.data.length;
        let result = [];
        for (let i = 0; i < total; i++) {
            let item: any = {
                unlocked: i < entry.unlocked,
                completed: i < entry.completed,
                approved: i < entry.approved,
            };
            result.push(item);
        }
        return result;
    }

    private getReport(): void {
        this.idParam = this.route.snapshot.paramMap.get("betaId");
        this.depService.get("betas/" + this.idParam + "/report", {}, data => {
            this.nav.setNavItems("betas", [
                new NavItem(data.beta.name, "betas/" + data.beta.id),
                new NavItem("Report", "betas/" + data.beta.id + "/report", "bar_chart")
            ]);
            let totalCompleted = 0;
            let totalApproved = 0;
            for (let chapter of data.chapters) {
                totalCompleted += chapter.completed;
                totalApproved += chapter.approved;
            }
            this.progress = Math.round(totalCompleted / (data.chapters.length * data.readers.length) * 1000) / 10;
            this.approvedProgress = Math.round(totalApproved / (data.chapters.length * data.readers.length) * 1000) / 10;
            let startDate: Date = this.forge.sqlDateToJsDate(data.beta.startDate);
            let endDate: Date = this.forge.sqlDateToJsDate(data.beta.endDate);
            const diffTime = Math.abs(endDate.getTime() - new Date().getTime());
            const diffTimeTotal = Math.abs(endDate.getTime() - startDate.getTime());
            this.remainingDays = Math.max(0, Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
            this.totalDays = Math.ceil(diffTimeTotal / (1000 * 60 * 60 * 24));
            this.report = data;
            this.chapters.data = data.chapters;
            this.readers.data = data.readers;
            this.chaptersCols.update();
        });
    }
}
