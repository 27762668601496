export interface ForgeKeyValue<T> {
    key: string,
    value: T
}

export interface AuthInfo {
    token: string;
    displayName: string;
}

export interface DialogInfo {
    title: string;
    message: string;
}

export interface Calendar {
    id: number;
    worldId: number;
    name: string;
    epoch: number;
    daysInYear: number;
    placeIds: Array<any>;
    additional: any;
}

export class NavItem {
    public constructor(
        public title: string,
        public route: string,
        public icon: string = null,
        public level: number = 0
    ) { }
    
    public setLevel(level: number) {
        this.level = level;
    }
}

export const TOP_LEVEL_MENU: NavItem[] = [
    new NavItem("Books and Series", "books", "book"),
    new NavItem("Calendars", "calendars", "date_range"),
    new NavItem("Characters", "characters", "person_pin"),
    new NavItem("Events", "events", "event_note"),
    new NavItem("Groups", "groups", "group"),
    new NavItem("Notes", "notes", "note"),
    new NavItem("Places", "places", "terrain"),
    new NavItem("Readers", "readers", "local_library"),
    new NavItem("Rounds of Beta", "betas", "rate_review")
];
