import { Component, OnInit } from '@angular/core';
import { BasePage } from '../base-page';
import { MatDialog } from '@angular/material';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { WorldService } from 'src/app/logic/services/world.service';
import { ApiDataEndpointService } from 'src/app/logic/services/api-dep.service';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute } from '@angular/router';
import { NavItem } from 'src/app/logic/base';

@Component({
  selector: 'app-beta-chapter-report',
  templateUrl: './beta-chapter-report.component.html'
})
export class BetaChapterReportComponent extends BasePage implements OnInit {
    idParam: any;
    chapter: any;
    questions: []

    constructor(
        private depService: ApiDataEndpointService,
        private modelService: ApiModelService,
        public world: WorldService,
        public forge: ForgeService,
        public nav: ForgeNavService,
        private route: ActivatedRoute,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getChapter();
        });
    }

    private getChapter() {
        let chapterId = this.route.snapshot.paramMap.get("chapterId");
        this.modelService.get("chapters", chapterId, data => {
            this.chapter = data;
            this.getReport();
        });
    }

    private getReport(): void {
        this.idParam = this.route.snapshot.paramMap.get("betaId");
        this.depService.get("betas/" + this.idParam + "/report", { chapterId: this.chapter.id }, data => {
            this.nav.setNavItems("betas", [
                new NavItem(data.beta.name, "betas/" + data.beta.id),
                new NavItem("Report", "betas/" + data.beta.id + "/report", "bar_chart"),
                new NavItem(this.chapter.title, "betas/" + data.beta.id + "/report/" + this.chapter.id)
            ]);
            this.questions = data.questions;
        });
    }
}
