import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DialogInfo } from 'src/app/logic/base';

@Component({
  selector: 'forge-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogInfo) { }

}
