import { Component, OnInit } from '@angular/core';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { BasePage } from '../base-page';
import { ApiDataEndpointService } from 'src/app/logic/services/api-dep.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NavItem } from 'src/app/logic/base';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';

@Component({
    selector: 'app-beta-signup',
    templateUrl: './beta-signup.component.html',
    styleUrls: ['./beta-signup.component.scss']
})
export class BetaSignUpComponent extends BasePage implements OnInit {
    
    private defaultControls = {
        name: new FormControl("", [Validators.required]),
        email: new FormControl("", [Validators.required, Validators.minLength(10), Validators.maxLength(100), Validators.pattern(/^.+@.+\..+$/)]),
        age: new FormControl("", [Validators.required, Validators.min(10), Validators.max(100), Validators.pattern(/^[0-9]*$/)]),
        location: new FormControl("", [Validators.required]),
        gender: new FormControl("", [Validators.required]),
        platform: new FormControl("", [Validators.required]),
        firstRound: new FormControl(false),
        lastRound: new FormControl(false),
        midRounds: new FormControl(false)
    };

    signupForm: FormGroup = new FormGroup(this.defaultControls);
    signup: any;
    prompt: SafeHtml;
    done: boolean = false;

    constructor(
        private nav: ForgeNavService,
        private depService: ApiDataEndpointService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        public forge: ForgeService,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        this.getSignup();
    }

    onSubmit() {
        this.signupForm.markAllAsTouched();
        if (!this.signupForm.valid) {
            this.alert("Please review the problems and try again.", "Submit Application");
            return;
        }
        let params = this.route.snapshot.paramMap;
        var query = {
            id: params.get("betaId"),
            cs: params.get("checksum")
        };
        let value = this.signupForm.value;
        let questions = [];
        for (let question of this.signup.questions) {
            let item = {
                id: question.id,
                betaId: query.id,
                answer: value["question" + question.id]
            }
            questions.push(item);
        }
        let body = {
            name: value.name,
            email: value.email,
            age: value.age,
            location: value.location,
            gender: value.gender,
            platform: value.platform,
            roundPreference: (value.firstRound ? 1 : 0) | (value.midRounds ? 2 : 0) | (value.lastRound ? 4 : 0),
            questions: questions
        };

        this.depService.post("beta-signup", query, body, data => { this.done = true; } );
    };

    getBookTitle(): string {
        if (!this.signup) return "";
        let title = this.signup.beta.book.title;
        let subtitle = this.signup.beta.book.subtitle;
        if (subtitle && subtitle != "") title += ": " + subtitle;
        return title;
    }

    isInvalid(name: string): boolean {
        let control = this.signupForm.controls[name];
        return control.touched && !control.valid;
    }

    private getSignup() {
        let params = this.route.snapshot.paramMap;
        let query = {
            id: params.get("betaId"),
            cs: params.get("checksum")
        };
        this.depService.get("beta-signup", query, data => {
            this.createFormGroup(data);
            this.signup = data;
            this.prompt = this.sanitizer.bypassSecurityTrustHtml(data.beta.signupText);
            this.nav.setNavItems(null, [
                new NavItem("Application Form", "beta-signup/" + query.id + "/" + query.cs)
            ]);
        });
    }

    private createFormGroup(signup: any) {
        let controls: any = {
            name: this.defaultControls.name,
            email: this.defaultControls.email,
            age: this.defaultControls.age,
            location: this.defaultControls.location,
            gender: this.defaultControls.gender,
            platform: this.defaultControls.platform,
            firstRound: this.defaultControls.firstRound,
            lastRound: this.defaultControls.lastRound,
            midRounds: this.defaultControls.midRounds,
        };
        for (let question of signup.questions) {
            controls["question" + question.id] = question.setup.required ? new FormControl('' || '', [Validators.required]) : new FormControl('' || '');
        }
        this.signupForm = new FormGroup(controls);
    }
}
