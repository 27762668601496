import { Component, OnInit } from '@angular/core';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { BasePage } from '../base-page';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'app-worlds',
    templateUrl: './worlds.component.html',
    styleUrls: ['./worlds.component.scss']
})
export class WorldsComponent extends BasePage implements OnInit {
    worlds = [];

    constructor(
        public nav: ForgeNavService,
        private modelService: ApiModelService,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit(): void {
        this.getWorlds();
    }

    getWorlds(): void {
        this.nav.setNavItems(null, []);
        this.modelService.getAll("worlds", new Object(), (data) => {
            this.worlds = data;
        });
    }

    deleteWorld(world: any): void {
        this.confirm("This action is not reversible.", "Delete '" + world.name + "'?").subscribe((result) => {
            result && this.modelService.delete("worlds", world.id, () => this.getWorlds());
        });
    }

    public canDelete(world: any): boolean {
        return world.allowedActions.indexOf("DELETE") >= 0;
    }
}
