import { Component, OnInit } from '@angular/core';
import { BasePage } from '../base-page';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { MatDialog } from '@angular/material';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/logic/services/api.service';
import { CrossFieldErrorStateMatcher } from 'src/app/logic/utils';
import { NavItem } from 'src/app/logic/base';

@Component({
    selector: 'app-me',
    templateUrl: './me.component.html',
    styleUrls: ['./me.component.scss']
})
export class MeComponent extends BasePage implements OnInit {

    id: number;
    username: string;
    crossField = new CrossFieldErrorStateMatcher();

    userForm = new FormGroup({
        displayName: new FormControl("", [Validators.required]),
        email: new FormControl("", [Validators.required]),
    });

    passwordForm = new FormGroup({
        currentPassword: new FormControl("", [Validators.required]),
        password: new FormControl("", [Validators.required, Validators.minLength(6)]),
        repeatPassword: new FormControl("", [Validators.required])
    }, (fg: FormGroup) => {
        const password = fg.get('password').value;
        const repeat = fg.get('repeatPassword').value;
        if (password != repeat) return { passwordMatch: "Repeated password does not match password." };
        return null;
    });

    constructor(
        public nav: ForgeNavService,
        private api: ApiService,
        private modelService: ApiModelService,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        this.getUser();
    }

    getUser(): void {
        this.modelService.get("users", "me", data => {
            this.nav.setNavItems(null, [
                new NavItem("My Account", "/me")
            ]);
            this.id = data.id;
            this.username = data.username;
            this.userForm.setValue({
                displayName: data.displayName,
                email: data.email
            })
            this.api.getAuth().displayName = data.displayName;
        });
    }

    saveUser(): void {
        let value = this.userForm.value;
        let body = {
            id: this.id,
            displayName: value.displayName,
            email: value.email
        };
        this.modelService.update("users", body, data => {
            this.getUser();
        });
    }

    changePassword(): void {
        let value = this.passwordForm.value;
        let body = {
            id: this.id,
            password: value.password,
            currentPassword: value.currentPassword
        };
        this.modelService.update("users", body, data => {
            this.getUser();
        });
    }
}
