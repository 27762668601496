import { Component, OnInit, ViewChild, Inject, HostListener } from '@angular/core';
import { BasePage } from '../base-page';
import { MatDialog, MatInput, MatAutocompleteSelectedEvent } from '@angular/material';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute } from '@angular/router';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { NavItem, Calendar } from 'src/app/logic/base';
import { ApiRelationService } from 'src/app/logic/services/api-relation.service';
import { FormControl } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { CalendarDate } from 'src/app/logic/utils';
import { ApiAvatarService } from 'src/app/logic/services/api-avatar.service';

interface TimelineSettings {
    startDate: number,
    endDate: number | null,
    addedCharacterIds: number[]
}

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent extends BasePage implements OnInit {
    STORAGE_KEY_PREFIX = 'forge_timeline_settings_';

    @ViewChild('characterInput', { static: true }) characterInput: MatInput;
    newCharacterControl = new FormControl("");

    settings: TimelineSettings = {
        startDate: 0,
        endDate: null,
        addedCharacterIds: []
    };

    gridColumns = 1;

    calendar: Calendar;
    characters: any[] = [];
    involvements: any[] = [];
    residencies: any[] = [];
    
    idParam: any;

    events: any[] = [];
    filteredEvents: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);

    otherCharacterIds: number[] = [];
    filteredOtherCharacterIds: Observable<number[]>;

    constructor(
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private modelService: ApiModelService,
        private relationService: ApiRelationService,
        private route: ActivatedRoute,
        public avatarService: ApiAvatarService,
        public world: WorldService,
        public nav: ForgeNavService,
        dialog: MatDialog
    ) {
        super(dialog);
        this.idParam = this.route.snapshot.paramMap.get("calendarId");
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getCalendar();
        });
        this.filteredOtherCharacterIds = this.newCharacterControl.valueChanges.pipe(
            startWith(""),
            map(value => this.filterOtherCharacterIds(value))
        );
        this.gridColumns = (window.innerWidth - 200) / 420;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.gridColumns = (window.innerWidth - 200) / 420;
    }

    save(): void {
        this.storage.set(this.STORAGE_KEY_PREFIX + this.calendar.id, this.settings);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        for (let item of this.characters) {
            if (item && item.name == event.option.viewValue) {
                this.addCharacter(item.id);
                break;
            }
        }
        this.newCharacterControl.setValue("");
        (<any>this.characterInput).nativeElement.value = "";
    }

    dateString(days: number) {
        if (!this.calendar) return "";
        return new CalendarDate(days, this.calendar).toString();
    }

    addCharacter(id: number): void {
        let i: number = this.otherCharacterIds.indexOf(id);
        if (i < 0) return;
        this.settings.addedCharacterIds.push(id);
        this.otherCharacterIds.splice(i, 1);
        this.filterEvents();
        this.save();
    }

    removeCharacter(id: number): void {
        let i: number = this.settings.addedCharacterIds.indexOf(id);
        if (i < 0) return;
        this.otherCharacterIds.push(id);
        this.settings.addedCharacterIds.splice(i, 1);
        this.filterEvents();
        this.save();
    }

    getRoleText(id: number): string {
        let character = this.characters[id];
        if (!character) return "";
        if (!character.role || character.role == "") {
            return "Role unspecified"
        }
        return character.role;
    }

    getAgeHtml(id: number, start: boolean): string {
        let character = this.characters[id];
        if (!character) return "";
        let selectedDay = start ? this.settings.startDate : this.settings.endDate;
        if (selectedDay == null || isNaN(selectedDay)) return "";
        if (character.birthDay == null) {
            return "<span class='unknown'>Age unknown</span>"
        }
        if (character.deathDay != null && selectedDay > character.deathDay) {
            return "<span class='dead'>Dead</span>";
        }
        if (selectedDay < character.birthDay) {
            return "<span class='unknown'>Not born yet</span>";
        }

        let daysInYear = this.calendar.daysInYear;
        let diff = selectedDay - character.birthDay;
        let year = Math.floor(diff / daysInYear);
        let day = diff % daysInYear;
        let ret = "";
        if (year > 0) {
            ret = ret + year + (year == 1 ? " year" : " years");
            if (day > 0) {
                ret += " and "
            }
        }
        if (day > 0) {
            ret = ret + day + (day == 1 ? " day" : " days");
        }
        if (ret == "") {
            return "<span class='alive'>Born on this day</span>";
        }

        return "<span class='alive'>" + ret + " old</span>";
    }

    getResidencyHtml(id: number): string {
        let character = this.characters[id];
        if (!character) return "";
        
        if (character.birthDay && this.settings.endDate && this.settings.endDate < character.birthDay) {
            return "";
        }
        
        if (character.deathDay && this.settings.startDate && this.settings.startDate > character.deathDay) {
            return "";
        }

        let relevantPlaces = new Array();

        for (let e of this.residencies) {
            if (e.character.id == id) {
                let startDay = e.startDay;
                let endDay = e.endDay;
                if (!startDay) {
                    if (character.birthDay != null) {
                        startDay = character.birthDay;
                    } else {
                        startDay = this.calendar.epoch;
                    }
                }
                if (!endDay) endDay = character.deathDay;
                if (!endDay) endDay = this.settings.endDate;
                if (!endDay || (endDay >= this.settings.startDate && startDay <= this.settings.endDate)) {
                    relevantPlaces.push('<a href="' + this.nav.getSubRoute("places/" + e.place.id) + '">' + e.place.name + "</a>");
                }
            }
        }

        if (relevantPlaces.length == 0) {
            return "Whereabouts are unknown.";
        }

        return "Resides in " + relevantPlaces.join(", ") + ".";
    }

    getInvolvementHtml(id: number): string {
        let character = this.characters[id];
        if (!character) return "";
        let relevantEvents = new Array();

        for (let e of this.events) {
            if (e.endDay == null) {
                e.endDay = e.startDay;
            }
            if (e.endDay >= this.settings.startDate && e.startDay <= this.settings.endDate) {
                for (let inv of this.involvements) {
                    if (inv.character.id == character.id && inv.event.id == e.id) {
                        relevantEvents.push('<a href="' + this.nav.getSubRoute("events/" + e.id) + '">' + e.title + "</a>");
                    }
                }
            }
        }

        if (relevantEvents.length == 0) {
            return "";
        }

        return "Involved in " + relevantEvents.join(", ") + ".";
    };

    filterEvents(): void {
        if (!this.events || !this.characters) return;
        let events: Array<any> = [];

        for (let id of this.settings.addedCharacterIds) {
            let character = this.characters[id];
            if (this.isInRange(character.birthDay, character.birthDay)) {
                events.push({
                    title: character.name,
                    description: character.name + " is born on this day.",
                    startDay: character.birthDay,
                    endDay: character.birthDay,
                    cssClass: "birth-dot",
                    subRoute: "characters/" + character.id
                });
            }
            if (this.isInRange(character.deathDay, character.deathDay)) {
                events.push({
                    title: character.name,
                    description: character.name + " dies on this day.",
                    startDay: character.deathDay,
                    endDay: character.deathDay,
                    cssClass: "death-dot",
                    subRoute: "characters/" + character.id
                });
            }
        }

        for (let event of this.events) {
            if (this.isInRange(event.startDay, event.endDay)) {
                events.push({
                    title: event.title,
                    description: event.description,
                    startDay: event.startDay,
                    endDay: event.endDay,
                    cssClass: "normal-dot",
                    subRoute: "events/" + event.id
                });
            }
        }

        this.filteredEvents.next(events.sort((a, b) => a.startDay - b.startDay));
    }

    private getCalendar(): void {
        this.modelService.get("calendars", parseInt(this.idParam), data => {
            this.nav.setNavItems("calendars", [
                new NavItem(data.name, "calendars/" + data.id),
                new NavItem("Timeline", "calendars/" + data.id + "/timeline", "timeline")
            ]);
            this.settings = this.storage.get(this.STORAGE_KEY_PREFIX + data.id) || {
                startDate: data.epoch,
                endDate: null,
                addedCharacterIds: []
            };
            this.calendar = data;
            this.calendar.daysInYear = new CalendarDate(null, this.calendar).getDaysInYear();
            this.getCharacters();
            this.getEvents();
            this.getInvolvements();
            this.getResidencies();
        });
    }

    private getCharacters(): void {
        this.modelService.getAll(
            "characters",
            { worldId: this.world.id },
            data => {
                let addedCharacterIds = [];
                let otherCharacterIds = [];
                let characters = [];
                for (let item of data) {
                    if (this.settings.addedCharacterIds.indexOf(item.id) < 0)
                        otherCharacterIds.push(item.id);
                    else addedCharacterIds.push(item.id);
                    characters[item.id] = item;
                }
                this.characters = characters;
                this.otherCharacterIds = otherCharacterIds;
                this.settings.addedCharacterIds = addedCharacterIds;
                this.filterEvents();
            }
        );
    }

    private getEvents(): void {
        this.modelService.getAll("events", { worldId: this.world.id }, data => { this.events = data; });
    }

    private getInvolvements(): void {
        this.relationService.getAll("involvements", { worldId: this.world.id }, data => { this.involvements = data; });
    }

    private getResidencies(): void {
        this.relationService.getAll("residencies", { worldId: this.world.id }, data => { this.residencies = data; });
    }

    private filterOtherCharacterIds(value: string): number[] {
        const filterValue = value ? value.toLowerCase() : "";
        return this.otherCharacterIds.filter(option => this.characters[option] ? this.characters[option].name.toLowerCase().includes(filterValue) : false);
    }

    private isInRange(start: number, end: number): boolean {
        if (!start || !end) { return false; }
        let passesStart: boolean = !this.settings.startDate || end >= this.settings.startDate;
        let passesEnd: boolean = !this.settings.endDate || start <= this.settings.endDate;
        return passesStart && passesEnd;
    }
}
