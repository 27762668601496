import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { WorldService } from 'src/app/logic/services/world.service';

@Component({
    selector: 'app-residency',
    templateUrl: './residency.component.html'
})
export class ResidencyComponent {
    residencyForm = new FormGroup({
        characterId: new FormControl(null, [Validators.required]),
        placeId: new FormControl(null, [Validators.required]),
        startDay: new FormControl(null, []),
        endDay: new FormControl(null, []),
        notes: new FormControl("", []),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public forge: ForgeService,
        public world: WorldService,
        public dialog: MatDialogRef<ResidencyComponent>
    ) {
        this.residencyForm.setValue({
            characterId: this.data.character ? this.data.character.id : null,
            placeId: this.data.place ? this.data.place.id : null,
            startDay: this.data.startDay,
            endDay: this.data.endDay,
            notes: this.data.notes
        });
    }

    onSubmit() {
        var value = this.residencyForm.value;
        var body = {
            id: this.data.id,
            worldId: this.world.id,
            character: {
                id: value.characterId
            },
            place: {
                id: value.placeId
            },
            startDay: value.startDay,
            endDay: value.endDay,
            notes: value.notes
        };
        this.dialog.close(body);
    }
}
