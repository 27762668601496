import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorldsComponent } from './pages/worlds/worlds.component';
import { WorldComponent } from './pages/world/world.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SeriesComponent } from './pages/series/series.component';
import { BooksComponent } from './pages/books/books.component';
import { BookComponent } from './pages/book/book.component';
import { ChapterComponent } from './pages/chapter/chapter.component';
import { CalendarsComponent } from './pages/calendars/calendars.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { TimelineComponent } from './pages/timeline/timeline.component';
import { CharactersComponent } from './pages/characters/characters.component';
import { EventsComponent } from './pages/events/events.component';
import { GroupsComponent } from './pages/groups/groups.component';
import { ReadersComponent } from './pages/readers/readers.component';
import { PlacesComponent } from './pages/places/places.component';
import { BetasComponent } from './pages/betas/betas.component';
import { NotesComponent } from './pages/notes/notes.component';
import { GroupComponent } from './pages/group/group.component';
import { EventComponent } from './pages/event/event.component';
import { CharacterComponent } from './pages/character/character.component';
import { PlaceComponent } from './pages/place/place.component';
import { ReaderComponent } from './pages/reader/reader.component';
import { FeedbacksComponent } from './pages/feedbacks/feedbacks.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { BetaComponent } from './pages/beta/beta.component';
import { QuestionsComponent } from './pages/questions/questions.component';
import { MeComponent } from './pages/me/me.component';
import { IndexComponent } from './pages/index/index.component';
import { QuestionnaireComponent } from './pages/questionnaire/questionnaire.component';
import { QuestionnaireOverviewComponent } from './pages/questionnaire-overview/questionnaire-overview.component';
import { BetaSignUpComponent } from './pages/beta-signup/beta-signup.component';
import { BetaApplicationComponent } from './pages/beta-application/beta-application.component';
import { BetaReportComponent } from './pages/beta-report/beta-report.component';
import { BetaChapterReportComponent } from './pages/beta-chapter-report/beta-chapter-report.component';

const routes: Routes = [
    { path: '', component: IndexComponent },
    { path: 'beta-signup/:betaId/:checksum', component: BetaSignUpComponent },
    { path: 'me', component: MeComponent },
    { path: 'questionnaire/:readingUid', component: QuestionnaireOverviewComponent },
    { path: 'questionnaire/:readingUid/:sectionId', component: QuestionnaireComponent },
    { path: 'worlds', component: WorldsComponent },
    { path: 'worlds/:worldId', component: WorldComponent },
    { path: 'worlds/:worldId/beta-applications/:applicationId', component: BetaApplicationComponent },
    { path: 'worlds/:worldId/betas', component: BetasComponent },
    { path: 'worlds/:worldId/betas/:betaId', component: BetaComponent },
    { path: 'worlds/:worldId/betas/:betaId/report', component: BetaReportComponent },
    { path: 'worlds/:worldId/betas/:betaId/report/:chapterId', component: BetaChapterReportComponent },
    { path: 'worlds/:worldId/books', component: BooksComponent },
    { path: 'worlds/:worldId/books/:bookId', component: BookComponent },
    { path: 'worlds/:worldId/books/:bookId/chapters/:chapterId', component: ChapterComponent },
    { path: 'worlds/:worldId/books/series/:seriesId', component: SeriesComponent },
    { path: 'worlds/:worldId/calendars', component: CalendarsComponent },
    { path: 'worlds/:worldId/calendars/:calendarId', component: CalendarComponent },
    { path: 'worlds/:worldId/calendars/:calendarId/timeline', component: TimelineComponent },
    { path: 'worlds/:worldId/characters', component: CharactersComponent },
    { path: 'worlds/:worldId/characters/:characterId', component: CharacterComponent },
    { path: 'worlds/:worldId/events', component: EventsComponent },
    { path: 'worlds/:worldId/events/:eventId', component: EventComponent },
    { path: 'worlds/:worldId/feedbacks/:readingUid', component: FeedbacksComponent },
    { path: 'worlds/:worldId/feedbacks/:readingUid/:sectionId', component: FeedbackComponent },
    { path: 'worlds/:worldId/groups', component: GroupsComponent },
    { path: 'worlds/:worldId/groups/:groupId', component: GroupComponent },
    { path: 'worlds/:worldId/notes', component: NotesComponent },
    { path: 'worlds/:worldId/notes/:noteId', component: NotesComponent },
    { path: 'worlds/:worldId/places', component: PlacesComponent },
    { path: 'worlds/:worldId/places/:placeId', component: PlaceComponent },
    { path: 'worlds/:worldId/questions/:betaId/:chapterId', component: QuestionsComponent },
    { path: 'worlds/:worldId/readers', component: ReadersComponent },
    { path: 'worlds/:worldId/readers/:readerId', component: ReaderComponent },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
