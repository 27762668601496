import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatAutocompleteSelectedEvent, MatInput, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { NavItem } from 'src/app/logic/base';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { BasePage } from '../base-page';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MembershipComponent } from 'src/app/dialogs/membership/membership.component';
import { BigTableColumns } from 'src/app/logic/utils';
import { ApiRelationService } from 'src/app/logic/services/api-relation.service';

@Component({
    selector: 'app-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss']
})
export class GroupComponent extends BasePage implements OnInit {
    @ViewChild('placeInput', { static: true }) placeInput: MatInput;
    newTerritoryControl = new FormControl("");

    @ViewChild('membershipsSort', { static: true }) membershipsSort: MatSort;
    @ViewChild('membershipsPaginator', { static: true }) membershipsPaginator: MatPaginator;
    membershipsCols: BigTableColumns = new BigTableColumns(['character.name', 'role', 'actions'], ['role']);
    memberships = new MatTableDataSource([]);

    groupForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        description: new FormControl('', []),
        population: new FormControl('', [Validators.pattern("[0-9]+")])
    });

    additionalForm = new FormGroup({
        additional: new FormControl('', [Validators.required])
    })

    groupPlaceIds: number[] = [];
    otherPlaceIds: number[] = [];
    filteredOtherPlaceIds: Observable<number[]>;
    places = [];
    expanded = [];

    additionals = new Map<string, any>();
    idParam: any;
    isExisting: boolean = false;

    constructor(
        public forge: ForgeService,
        public nav: ForgeNavService,
        public world: WorldService,
        private modelService: ApiModelService,
        private relationService: ApiRelationService,
        private route: ActivatedRoute,
        private router: Router,
        dialog: MatDialog
    ) {
        super(dialog);
        this.memberships.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'character.name': return item.character.name;
                default: return item[property];
            }
        };
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getGroup();
            this.getPlaces();
        });
        this.filteredOtherPlaceIds = this.newTerritoryControl.valueChanges.pipe(
            startWith(""),
            map(value => this.filterOtherPlaceIds(value))
        );
        this.memberships.sort = this.membershipsSort;
        this.memberships.paginator = this.membershipsPaginator;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.membershipsCols.update();
    }

    save() {
        var value = this.groupForm.value;
        var additional = this.forge.mapModelToObject(this.additionals);
        var body = {
            id: this.isExisting ? parseInt(this.idParam) : null,
            worldId: this.world.id,
            name: value.name,
            description: value.description,
            population: value.population,
            placeIds: this.groupPlaceIds,
            additional: additional
        };

        if (this.isExisting) {
            this.modelService.update("factions", body, data => { });
        } else {
            this.modelService.create("factions", body, data => {
                this.router.navigate([this.nav.getSubRoute("groups/" + data.id)]);
                this.ngOnInit();
            });
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        for (let item of this.places) {
            if (item && item.name == event.option.viewValue) {
                this.addPlace(item.id);
                break;
            }
        }
        this.newTerritoryControl.setValue("");
        (<any>this.placeInput).nativeElement.value = "";
    }

    addPlace(id: number): void {
        let i: number = this.otherPlaceIds.indexOf(id);
        if (i < 0) return;
        this.groupPlaceIds.push(id);
        this.otherPlaceIds.splice(i, 1);
    }

    removePlace(id: number): void {
        let i: number = this.groupPlaceIds.indexOf(id);
        if (i < 0) return;
        this.otherPlaceIds.push(id);
        this.groupPlaceIds.splice(i, 1);
    }

    addAdditional() {
        var value = this.additionalForm.value;
        if (!this.additionals.has(value.additional)) {
            this.additionals.set(value.additional, { value: "" });
        }
        this.additionalForm.setValue({
            additional: ""
        });
    }

    deleteAdditional(key: string) {
        this.additionals.delete(key);
    }

    editMembership(membership: any): void {
        if (membership == null) {
            this.modelService.getAll("characters", { worldId: this.world.id }, (data: any) => {
                this.dialog.open(MembershipComponent, {
                    data: {
                        character: null,
                        group: { id: parseInt(this.idParam), name: this.groupForm.value.name },
                        characters: data,
                        groups: null,
                        role: "",
                        notes: "",
                        memberships: this.memberships.data
                    }
                }).afterClosed().subscribe(body => {
                    if (body) {
                        body.worldId = this.world.id;
                        this.relationService.create("memberships", body, (data: any) => { this.getMemberships(); });
                    }
                });
            });
        } else {
            this.dialog.open(MembershipComponent, {
                data: {
                    character: membership.character,
                    group: membership.faction,
                    characters: null,
                    groups: null,
                    role: membership.role,
                    notes: membership.notes,
                    memberships: this.memberships.data
                }
            }).afterClosed().subscribe(body => {
                if (body) this.relationService.update("memberships", body.character.id, body.faction.id, body, (data: any) => { this.getMemberships(); });
            });
        }
    }

    deleteMembership(membership: any): void {
        this.confirm("'" + membership.character.name + "' will be permanently removed from the group.", "Delete Membership?").subscribe(
            result => result && this.relationService.delete("memberships", membership.character.id, this.idParam, () => { this.getMemberships(); })
        );
    }

    private getGroup(): void {
        this.idParam = this.route.snapshot.paramMap.get("groupId");

        if (this.idParam == "new") {
            this.isExisting = false;
            this.nav.setNavItems(null, []);
            this.groupForm.setValue({
                name: "",
                description: "",
                population: ""
            });
            this.additionals = this.forge.objectToMapModel({});
            this.groupPlaceIds = [];
        } else {
            this.isExisting = true;
            this.modelService.get("factions", parseInt(this.idParam), data => {
                this.nav.setNavItems("groups", [
                    new NavItem(data.name, "groups/" + data.id)
                ]);
                this.groupForm.setValue({
                    name: data.name,
                    description: data.description,
                    population: data.population ? data.population : ""
                });
                this.getMemberships();
                this.additionals = this.forge.objectToMapModel(data.additional);
                this.groupPlaceIds = data.placeIds;
            });
        }
    }

    private getPlaces(): void {
        this.modelService.getAll(
            "places",
            { worldId: this.world.id },
            data => {
                let otherPlaceIds = [];
                let places = [];
                for (let item of data) {
                    if (this.groupPlaceIds.indexOf(item.id) < 0) otherPlaceIds.push(item.id);
                    places[item.id] = item;
                }
                this.places = places;
                this.otherPlaceIds = otherPlaceIds;
            }
        );
    }

    private getMemberships(): void {
        let id = parseInt(this.idParam);
        this.relationService.getAll(
            "memberships",
            { factionId: id },
            data => {
                this.memberships.data = data;
            }
        );
    }

    private filterOtherPlaceIds(value: string): number[] {
        const filterValue = value ? value.toLowerCase() : "";
        return this.otherPlaceIds.filter(option => this.places[option] ? this.places[option].name.toLowerCase().includes(filterValue) : false);
    }
}
