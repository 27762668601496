import { Component, OnInit } from '@angular/core';
import { ApiDataEndpointService } from 'src/app/logic/services/api-dep.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { BasePage } from '../base-page';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { NavItem } from 'src/app/logic/base';

@Component({
    selector: 'app-questionnaire',
    templateUrl: './questionnaire.component.html',
    styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent extends BasePage implements OnInit {
    uid: string;
    sectionId: number | string;
    questionnaire: any = {};
    questionsForm: FormGroup = new FormGroup({
        general: new FormControl(""),
        rating: new FormControl(null)
    });
    rating: number | null = null;

    constructor(
        private nav: ForgeNavService,
        private depService: ApiDataEndpointService,
        private router: Router,
        private route: ActivatedRoute,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        this.getQuestionnaire();
    }

    onSubmit() {
        let value = this.questionsForm.value;
        this.questionnaire.feedback.general = value.general;
        this.questionnaire.feedback.rating = this.rating;
        for (let question of this.questionnaire.questions) {
            question.answer = value["question" + question.id];
        }
        this.depService.post("questionnaire", { reading: this.uid, section: this.sectionId }, this.questionnaire, data => {
            this.router.navigate(['/questionnaire/' + this.uid]);
        });
    }

    getBookTitle(): string {
        if (!this.questionnaire.book) return "";
        let title = this.questionnaire.book.title;
        let subtitle = this.questionnaire.book.subtitle;
        if (subtitle && subtitle != "") title += ": " + subtitle;
        return title;
    }

    private getQuestionnaire(): void {
        let params = this.route.snapshot.paramMap;
        this.uid = params.get("readingUid");
        this.sectionId = params.get("sectionId");
        if (this.sectionId == "general") this.sectionId = "null";
        this.depService.get("questionnaire", { reading: this.uid, section: this.sectionId }, data => {
            this.createFormGroup(data);
            this.questionnaire = data;
            this.nav.setNavItems(null, [
                new NavItem(data.book.title, "questionnaire/" + this.uid),
                new NavItem(data.chapter.title, "questionnaire/" + this.uid + "/" + this.sectionId)
            ]);
        });
    }

    private createFormGroup(questionnaire: any) {
        let group: any = {};
        group["general"] = new FormControl(questionnaire.feedback.general ? questionnaire.feedback.general : "", [Validators.required]);
        this.rating = questionnaire.feedback.rating;
        for (let question of questionnaire.questions) {
            group["question" + question.id] = question.setup.required ? new FormControl(question.answer || '', [Validators.required]) : new FormControl(question.answer || '');
        }
        this.questionsForm = new FormGroup(group);
    }
}
