import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeService } from 'src/app/logic/services/forge.service';

@Component({
    selector: 'app-reading',
    templateUrl: './reading.component.html'
})
export class ReadingComponent {
    loadingSub: Subscription;

    readingForm = new FormGroup({
        betaId: new FormControl(null, [Validators.required]),
        readerId: new FormControl(null, [Validators.required]),
        platform: new FormControl("UNKNOWN", [Validators.required]),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public forge: ForgeService,
        public world: WorldService,
        public dialog: MatDialogRef<ReadingComponent>
    ) {
        this.readingForm.setValue({
            betaId: this.data.betaId,
            readerId: this.data.readerId,
            platform: this.data.platform
        });
        if (data.readers && data.readings) {
            let readers = [];
            for (let reader of data.readers) {
                let found = false;
                for (let reading of data.readings) {
                    if (reading.reader.id == reader.id) found = true;
                }
                if (!found) readers.push(reader);
            }
            data.readers = readers;
        }
    }

    onSubmit() {
        var value = this.readingForm.value;
        var body = {
            worldId: this.world.id,
            beta: {
                id: value.betaId
            },
            reader: {
                id: value.readerId
            },
            platform: value.platform
        };
        this.dialog.close(body);
    }
}
