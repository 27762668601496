import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-beta-approval',
    templateUrl: './beta-approval.component.html',
    styleUrls: ['./beta-approval.component.scss']
})
export class BetaApprovalComponent {
    loadingSub: Subscription;

    availablePlaces = [];
    availableEvents = [];

    applicationForm = new FormGroup({
        updateExisting: new FormControl(false, [Validators.required]),
        addToBeta: new FormControl(true, [Validators.required]),
        readerId: new FormControl("", [])
    }, (fg: FormGroup) => {
        const updateExisting = fg.get('updateExisting').value;
        const readerId = fg.get('readerId').value;
        if ((readerId == "" || !readerId) && updateExisting)
            return { readerValidator: "Reader is required." };
        return null;
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialog: MatDialogRef<BetaApprovalComponent>
    ) { }

    onSubmit() {
        var value = this.applicationForm.value;
        var result = {
            updateExisting: value.updateExisting,
            addToBeta: value.addToBeta,
            readerId: value.readerId == "" ? null : parseInt(value.readerId)
        };
        this.dialog.close(result);
    }
}
