import { Component } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { AuthInfo } from 'src/app/logic/base';
import { MatDialogRef } from '@angular/material';
import { ApiService } from 'src/app/logic/services/api.service';

@Component({
  selector: 'login-overlay',
  templateUrl: './login.component.html'
})
export class LoginComponent {
  working = false;
  hidePassword = true;

  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(public dialog: MatDialogRef<LoginComponent>, private api: ApiService) { }

  onSubmit() {
    var value = this.loginForm.value;
    this.working = true;
    this.api.signIn(value.username, value.password).subscribe(
      (auth: AuthInfo) => {
        this.working = false;
        this.dialog.close();
      },
      error => {
        this.working = false;
      }
    );
  }
}

