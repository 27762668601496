import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatSort } from '@angular/material';
import { BookDialogComponent } from 'src/app/dialogs/book/book.component';
import { BigTableColumns } from 'src/app/logic/utils';
import { NavItem } from 'src/app/logic/base';
import { LoaderService } from 'src/app/logic/services/loader.service';
import { Subscription } from 'rxjs';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { BasePage } from '../base-page';

@Component({
    selector: 'app-serie',
    templateUrl: './series.component.html'
})
export class SeriesComponent extends BasePage implements OnInit {
    seriesForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        author: new FormControl('', [])
    });

    @ViewChild(MatSort, { static: true }) booksSort: MatSort;
    booksCols: BigTableColumns = new BigTableColumns(['seriesIndex', 'title', 'subtitle', 'author', 'actions'], ['seriesIndex', 'subtitle', 'author']);

    seriesBooks = new MatTableDataSource([]);
    otherBooks = [];
    idParam: any;
    isExisting: boolean = false;

    constructor(
        public nav: ForgeNavService,
        public world: WorldService,
        private modelService: ApiModelService,
        private route: ActivatedRoute,
        private router: Router,
        private loader: LoaderService,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getSeries();
        });
        this.booksCols.update();
        this.seriesBooks.sort = this.booksSort;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.booksCols.update();
    }

    save() {
        var value = this.seriesForm.value;
        var body = {
            id: parseInt(this.idParam),
            worldId: this.world.id,
            title: value.title,
            author: value.author
        };
        if (this.isExisting) {
            this.modelService.update("series", body, data => { });
        } else {
            this.modelService.create("series", body, data => {
                this.router.navigate([this.nav.getSubRoute("books/series/" + data.id)]);
                this.ngOnInit();
            });
        }
    }

    addBook(): void {
        this.dialog.open(BookDialogComponent, {
            data: {
                books: this.otherBooks
            }
        }).afterClosed().subscribe((book) => {
            book.seriesId = parseInt(this.idParam);
            this.modelService.update("books/" + book.id, book, data => { this.getBooks(); });
        });
    }

    removeBook(book: any): void {
        book.seriesId = null;
        this.modelService.update("books/" + book.id, book, data => { this.getBooks(); });
    }

    saveOrder(): void {
        var i: number = 0;
        for (let book of this.seriesBooks.sortData(this.seriesBooks.filteredData, this.seriesBooks.sort)) {
            book.seriesIndex = ++i;
            this.modelService.update("books/" + book.id, book, data => { });
        }
        let subscription: Subscription = this.loader.isLoading.subscribe((loading) => {
            if (!loading) {
                subscription.unsubscribe();
                this.getBooks();
            }
        });
    }

    private getSeries(): void {
        this.idParam = this.route.snapshot.paramMap.get("seriesId");

        if (this.idParam == "new") {
            this.isExisting = false;
            this.nav.setNavItems(null, []);
            this.seriesForm.setValue({
                title: '',
                author: ''
            });
        } else {
            this.isExisting = true;
            this.modelService.get("series", parseInt(this.idParam), data => {
                this.nav.setNavItems("books", [
                    new NavItem(data.title, "books/series/" + data.id)
                ]);
                this.getBooks();
                this.seriesForm.setValue({
                    title: data.title,
                    author: data.author
                });
            });
        }
    }

    private getBooks(): void {
        let id = parseInt(this.idParam);
        this.modelService.getAll(
            "books",
            { worldId: this.world.id },
            data => {
                let seriesBooks = [], otherBooks = [];
                for (let book of data) {
                    if (book.seriesId == id) seriesBooks.push(book); else otherBooks.push(book);
                }
                this.otherBooks = otherBooks;
                this.seriesBooks.data = seriesBooks;
            }
        );
    }
}
