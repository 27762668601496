import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WorldService {
    public id: number = -1;
    public name: string;
    public description: string;
    public calendarId: number = -1;
    public allowedActions: string[] = [];

    constructor() { }

    public set(data: any): void {
        if (!data) {
            this.id = null;
            return;
        }
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.calendarId = data.calendarId;
        this.allowedActions = data.allowedActions;
    }

    public isLoaded(): boolean {
        return this.id >= 0;
    }

    public canCreate(): boolean {
        if (!this.isLoaded()) return false;
        return this.allowedActions.indexOf("CREATE") >= 0;
    }

    public canUpdate(): boolean {
        if (!this.isLoaded()) return false;
        return this.allowedActions.indexOf("UPDATE") >= 0;
    }

    public canDelete(): boolean {
        if (!this.isLoaded()) return false;
        return this.allowedActions.indexOf("DELETE") >= 0;
    }
}
