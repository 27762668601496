import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { BasePage } from '../base-page';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { WorldService } from 'src/app/logic/services/world.service';
import { NavItem } from 'src/app/logic/base';
import { ApiDataEndpointService } from 'src/app/logic/services/api-dep.service';
import { BigTableColumns } from 'src/app/logic/utils';

@Component({
    selector: 'app-feedbacks',
    templateUrl: './feedbacks.component.html',
    styleUrls: ['./feedbacks.component.scss']
})
export class FeedbacksComponent extends BasePage implements OnInit {
    @ViewChild('sectionsSort', { static: true }) sectionsSort: MatSort;
    @ViewChild('sectionsPaginator', { static: true }) sectionsPaginator: MatPaginator;
    sectionsCols: BigTableColumns = new BigTableColumns(["title", "status", "rating", "actions"], ["rating", "status"]);
    sections = new MatTableDataSource([]);

    uid: string;
    questionnaire: any;

    constructor(
        public forge: ForgeService,
        public world: WorldService,
        public nav: ForgeNavService,
        private modelService: ApiModelService,
        private depService: ApiDataEndpointService,
        private route: ActivatedRoute,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getQuestionnaire();
        });
        this.sections.sort = this.sectionsSort;
        this.sections.paginator = this.sectionsPaginator;
    }

    @HostListener('window:resize', ['$event'])
    onResize(e) {
        this.sectionsCols.update();
    }

    getBookTitle(): string {
        let title = this.questionnaire.book.title;
        let subtitle = this.questionnaire.book.subtitle;
        if (subtitle && subtitle != "") title += ": " + subtitle;
        return title;
    }

    getStatusText(element: any): string {
        if (!element.unlocked) return "Locked";
        if (element.complete)
            return element.approved ? "Accepted" : "Completed";
        return element.approved ? "Canceled" : "Pending";
    }

    unlockSection(element: any): void {
        let feedback = {
            worldId: this.world.id,
            betaId: this.questionnaire.beta.id,
            readerId: this.questionnaire.reader.id,
            chapterId: element.id,
            startDate: this.forge.jsDateToSqlDate(new Date())
        };
        this.modelService.create("feedbacks", feedback, data => this.getQuestionnaire());
    }

    deleteSection(element: any): void {
        this.confirm("This action is not reversible.", "Delete feedback for '" + element.title + "'?").subscribe(
            result => result && this.modelService.delete("feedbacks", element.feedbackId, () => this.getQuestionnaire())
        ); 
    }

    private getQuestionnaire(): void {
        this.uid = this.route.snapshot.paramMap.get("readingUid");
        this.depService.get("questionnaire", { reading: this.uid }, data => {
            this.nav.setNavItems("readers", [
                new NavItem(data.reader.name, "readers/" + data.reader.id),
                new NavItem(data.beta.name, "feedbacks/" + this.uid)
            ]);
            this.questionnaire = data;
            this.getChapters();
        });
    }

    private getChapters() {
        this.modelService.getAll(
            "chapters",
            { bookId: this.questionnaire.book.id },
            data => {
                let sections: any[] = [{
                    id: "null",
                    title: "Sign-up Questions",
                    unlocked: true,
                    complete: true,
                    rating: "-"
                }];

                for (let section of data) {
                    section.unlocked = false;
                    for (let chapter of this.questionnaire.chapters) {
                        if (chapter.id == section.id) {
                            section.feedbackId = chapter.feedbackId;
                            section.unlocked = true;
                            section.approved = chapter.approved;
                            section.complete = chapter.complete;
                            section.rating = chapter.rating;
                            break;
                        }
                    }
                    sections.push(section);
                }
                this.sections.data = sections;
                this.sectionsCols.update();
            }
        );
    }
}
