import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { NavItem, Calendar } from 'src/app/logic/base';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { ApiRelationService } from 'src/app/logic/services/api-relation.service';
import { BasePage } from '../base-page';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { CalendarDate, BigTableColumns } from 'src/app/logic/utils';
import { InvolvementComponent } from 'src/app/dialogs/involvement/involvement.component';
import { LocaleComponent } from 'src/app/dialogs/locale/locale.component';

@Component({
    selector: 'app-event',
    templateUrl: './event.component.html'
})
export class EventComponent extends BasePage implements OnInit {
    @ViewChild('involvementsSort', { static: true }) involvementsSort: MatSort;
    @ViewChild('involvementsPaginator', { static: true }) involvementsPaginator: MatPaginator;
    involvementsCols: BigTableColumns = new BigTableColumns(["character.name", "role", "actions"], ["role"]);
    involvements = new MatTableDataSource([]);

    @ViewChild('localesSort', { static: true }) localesSort: MatSort;
    @ViewChild('localesPaginator', { static: true }) localesPaginator: MatPaginator;
    localesCols: BigTableColumns = new BigTableColumns(["place.name", "role", "actions"], ["role"]);
    locales = new MatTableDataSource([]);

    calendar: Calendar = null;
    event: any = null;
    idParam: any;
    isExisting: boolean = false;

    eventForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        startDay: new FormControl(null, []),
        endDay: new FormControl(null, []),
        description: new FormControl('', [])
    }, (fg: FormGroup) => {
        const start = fg.get('startDay').value;
        const end = fg.get('endDay').value;
        if (!start || !end) return null;
        return start <= end ? null : { rangeValidator: "End date cannot be before start date." };
    });

    constructor(
        public forge: ForgeService,
        public nav: ForgeNavService,
        public world: WorldService,
        private modelService: ApiModelService,
        private relationService: ApiRelationService,
        private route: ActivatedRoute,
        private router: Router,
        dialog: MatDialog
    ) {
        super(dialog);
        this.involvements.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'character.name': return item.character.name;
                default: return item[property];
            }
        };
        this.locales.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'place.name': return item.place.name;
                default: return item[property];
            }
        };
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getCalendar();
            this.getEvent();
        });
        this.involvements.sort = this.involvementsSort;
        this.involvements.paginator = this.involvementsPaginator;
        this.locales.sort = this.localesSort;
        this.locales.paginator = this.localesPaginator;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.involvementsCols.update();
        this.localesCols.update();
    }

    save() {
        var value = this.eventForm.value;
        var body = {
            id: this.isExisting ? parseInt(this.idParam) : null,
            worldId: this.world.id,
            title: value.title,
            startDay: value.startDay,
            endDay: value.endDay,
            description: value.description
        };

        if (this.isExisting) {
            this.modelService.update("events", body, data => { });
        } else {
            this.modelService.create("events", body, data => {
                this.router.navigate([this.nav.getSubRoute("events/" + data.id)]);
                this.ngOnInit();
            });
        }
    }

    editInvolvement(involvement: any): void {
        if (involvement == null) {
            this.modelService.getAll("characters", { worldId: this.world.id }, (data: any) => {
                this.dialog.open(InvolvementComponent, {
                    data: {
                        character: null,
                        event: { id: parseInt(this.idParam), title: this.eventForm.value.title },
                        characters: data,
                        events: null,
                        role: "",
                        involvements: this.involvements.data
                    }
                }).afterClosed().subscribe(body => {
                    if (body) {
                        body.worldId = this.world.id;
                        this.relationService.create("involvements", body, (data: any) => { this.getInvolvements(); });
                    }
                });
            });
        } else {
            this.dialog.open(InvolvementComponent, {
                data: {
                    character: involvement.character,
                    event: involvement.event,
                    characters: null,
                    events: null,
                    role: involvement.role,
                    involvements: this.involvements.data
                }
            }).afterClosed().subscribe(body => {
                if (body) this.relationService.update("involvements", body.character.id, body.event.id, body, (data: any) => { this.getInvolvements(); });
            });
        }
    }

    deleteInvolvement(involvement: any): void {
        this.relationService.delete("involvements", involvement.character.id, this.idParam, () => { this.getInvolvements(); });
    }

    editLocale(locale: any): void {
        if (locale == null) {
            this.modelService.getAll("places", { worldId: this.world.id }, (data: any) => {
                this.dialog.open(LocaleComponent, {
                    data: {
                        place: null,
                        event: { id: parseInt(this.idParam), title: this.eventForm.value.title },
                        places: data,
                        events: null,
                        role: "",
                        locales: this.locales.data
                    }
                }).afterClosed().subscribe(body => {
                    if (body) {
                        body.worldId = this.world.id;
                        this.relationService.create("locales", body, (data: any) => { this.getLocales(); });
                    }
                });
            });
        } else {
            this.dialog.open(LocaleComponent, {
                data: {
                    place: locale.place,
                    event: locale.event,
                    places: null,
                    events: null,
                    role: locale.role,
                    locales: this.locales.data
                }
            }).afterClosed().subscribe(body => {
                if (body) this.relationService.update("locales", body.place.id, body.event.id, body, (data: any) => { this.getLocales(); });
            });
        }
    }
    deleteLocale(locale: any): void {
        this.relationService.delete("locales", locale.place.id, this.idParam, () => { this.getLocales(); });
    }

    private getCalendar(): void {
        if (!this.world.calendarId) return;
        this.modelService.get("calendars", this.world.calendarId, data => {
            this.calendar = data;
            this.calendar.daysInYear = new CalendarDate(null, this.calendar).getDaysInYear();
        });
    }

    private getEvent(): void {
        this.idParam = this.route.snapshot.paramMap.get("eventId");

        if (this.idParam == "new") {
            this.isExisting = false;
            this.nav.setNavItems("events", []);
            this.eventForm.setValue({
                title: "",
                startDay: null,
                endDay: null,
                description: ""
            });
        } else {
            this.isExisting = true;
            this.modelService.get("events", parseInt(this.idParam), data => {
                this.nav.setNavItems("events", [
                    new NavItem(data.title, "events/" + data.id)
                ]);
                this.getInvolvements();
                this.getLocales();
                this.eventForm.setValue({
                    title: data.title,
                    startDay: data.startDay,
                    endDay: data.endDay,
                    description: data.description
                });
            });
        }
    }

    private getInvolvements(): void {
        let id = parseInt(this.idParam);
        this.relationService.getAll(
            "involvements",
            { eventId: id },
            data => {
                this.involvements.data = data;
            }
        );
    }

    private getLocales(): void {
        let id = parseInt(this.idParam);
        this.relationService.getAll(
            "locales",
            { eventId: id },
            data => {
                this.locales.data = data;
            }
        );
    }
}
