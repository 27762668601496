import { Component, OnInit } from '@angular/core';
import { BasePage } from '../base-page';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { WorldService } from 'src/app/logic/services/world.service';
import { NavItem } from 'src/app/logic/base';
import { ApiDataEndpointService } from 'src/app/logic/services/api-dep.service';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent extends BasePage implements OnInit {
    uid: string;
    sectionId: number | string;
    questionnaire: any;

    constructor(
        public forge: ForgeService,
        public world: WorldService,
        public nav: ForgeNavService,
        private modelService: ApiModelService,
        private depService: ApiDataEndpointService,
        private route: ActivatedRoute,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getQuestionnaire();
        });
    }

    getBookTitle(): string {
        let title = this.questionnaire.book.title;
        let subtitle = this.questionnaire.book.subtitle;
        if (subtitle && subtitle != "") title += ": " + subtitle;
        return title;
    }

    getStatusText(element: any): string {
        if (!element.unlocked) return "Locked";
        if (element.complete)
            return element.approved ? "Approved" : "Completed";
        return element.approved ? "Canceled" : "Pending";
    }

    unlockSection(element: any): void {
        let feedback = {
            worldId: this.world.id,
            betaId: this.questionnaire.beta.id,
            readerId: this.questionnaire.reader.id,
            chapterId: element.id,
            startDate: this.forge.jsDateToSqlDate(new Date())
        };
        this.modelService.create("feedbacks", feedback, data => this.getQuestionnaire());
    }

    deleteSection(element: any): void {
        this.confirm("This action is not reversible.", "Delete feedback for '" + element.title + "'?").subscribe(
            result => result && this.modelService.delete("feedbacks", element.feedbackId, () => this.getQuestionnaire())
        ); 
    }

    toggleFeedbackApproval(): void {
        let feedback = this.questionnaire.feedback;
        if (feedback.approved)
            feedback.approved = null;
        else feedback.approved = this.forge.jsDateToSqlDate(new Date());
        this.modelService.update("feedbacks", feedback, data => this.getQuestionnaire());
    }

    private getQuestionnaire(): void {
        let params = this.route.snapshot.paramMap;
        this.uid = params.get("readingUid");
        this.sectionId = params.get("sectionId");
        if (this.sectionId == "general") this.sectionId = "null";
        this.depService.get("questionnaire", { reading: this.uid, section: this.sectionId }, data => {
            this.nav.setNavItems("readers", [
                new NavItem(data.reader.name, "readers/" + data.reader.id),
                new NavItem(data.beta.name, "feedbacks/" + this.uid),
                new NavItem(data.chapter.title, "feedbacks/" + this.uid + "/" + this.sectionId)
            ]);
            this.questionnaire = data;
        });
    }
}
