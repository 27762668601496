import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog,MatInput, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { NavItem, Calendar } from 'src/app/logic/base';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { ForgeService } from 'src/app/logic/services/forge.service';
import { BasePage } from '../base-page';
import { BigTableColumns, CalendarDate } from 'src/app/logic/utils';
import { ApiRelationService } from 'src/app/logic/services/api-relation.service';
import { ResidencyComponent } from 'src/app/dialogs/residency/residency.component';
import { Subscription } from 'rxjs';
import { LocaleComponent } from 'src/app/dialogs/locale/locale.component';
import { map } from 'rxjs/operators';
import { PathComponent } from 'src/app/dialogs/path/path.component';

@Component({
    selector: 'app-place',
    templateUrl: './place.component.html',
    styleUrls: ['./place.component.scss']
})
export class PlaceComponent extends BasePage implements OnInit, OnDestroy {
    @ViewChild('placeInput', { static: true }) placeInput: MatInput;
    newTerritoryControl = new FormControl("");

    @ViewChild('placesSort', { static: true }) placesSort: MatSort;
    @ViewChild('placesPaginator', { static: true }) placesPaginator: MatPaginator;
    placesCols: BigTableColumns = new BigTableColumns(["name", "area", "population"], ["area", "population"]);
    childPlaces = new MatTableDataSource([]);

    @ViewChild('localesSort', { static: true }) localesSort: MatSort;
    @ViewChild('localesPaginator', { static: true }) localesPaginator: MatPaginator;
    localesCols: BigTableColumns = new BigTableColumns(["event.title", "role", "actions"], ["role"]);
    locales = new MatTableDataSource([]);

    @ViewChild('pathsSort', { static: true }) pathsSort: MatSort;
    @ViewChild('pathsPaginator', { static: true }) pathsPaginator: MatPaginator;
    pathsCols: BigTableColumns = new BigTableColumns(["title", "destination.name", "distance", "reversible", "actions"], ["title", "distance", "reversible"]);
    paths = new MatTableDataSource([]);

    @ViewChild('residenciesSort', { static: true }) residenciesSort: MatSort;
    @ViewChild('residenciesPaginator', { static: true }) residenciesPaginator: MatPaginator;
    residenciesCols: BigTableColumns = new BigTableColumns(["character.name", "startDay", "endDay", "actions"], ["startDay", "endDay"]);
    residencies = new MatTableDataSource([]);

    placeForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        type: new FormControl('', [Validators.required]),
        parentId: new FormControl(-1, []),
        description: new FormControl('', [Validators.required]),
        climate: new FormControl('', []),
        features: new FormControl('', []),
        population: new FormControl('', [Validators.pattern("[0-9]+")]),
        area: new FormControl('', [Validators.pattern("[0-9]+")])
    });

    additionalForm = new FormGroup({
        additional: new FormControl('', [Validators.required])
    })

    calendar: Calendar = null;
    places = [];
    expanded = [];

    additionals = new Map<string, any>();
    idParam: any;
    isExisting: boolean = false;

    map = {
    };

    idParamSubscription: Subscription;

    constructor(
        public forge: ForgeService,
        public nav: ForgeNavService,
        public world: WorldService,
        private modelService: ApiModelService,
        private relationService: ApiRelationService,
        private route: ActivatedRoute,
        private router: Router,
        dialog: MatDialog
    ) {
        super(dialog);
        this.locales.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'event.title': return item.event.title;
                default: return item[property];
            }
        };
        this.paths.sortingDataAccessor = (item, property) => {
            switch (property) {
                case "destination.name": return item.destination.name;
                default: return item[property];
            }
        };
        this.residencies.sortingDataAccessor = (item, property) => {
            switch (property) {
                case "character.name": return item.character.name;
                default: return item[property];
            }
        };
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.getCalendar();
            this.getPlace();
            this.getPlaces();
        });
        this.idParamSubscription = this.route.paramMap.subscribe(data => {
            this.getPlace();
            this.getPlaces();
        });
        this.childPlaces.sort = this.placesSort;
        this.childPlaces.paginator = this.placesPaginator;
        this.locales.sort = this.localesSort;
        this.locales.paginator = this.localesPaginator;
        this.paths.sort = this.pathsSort;
        this.paths.paginator = this.pathsPaginator;
        this.residencies.sort = this.residenciesSort;
        this.residencies.paginator = this.residenciesPaginator;
    }

    ngOnDestroy() {
        this.idParamSubscription.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.localesCols.update();
        this.pathsCols.update();
        this.placesCols.update();
        this.residenciesCols.update();
    }

    save() {
        var value = this.placeForm.value;
        var additional = this.forge.mapModelToObject(this.additionals);
        additional.__map__ = map;
        var body = {
            id: this.isExisting ? parseInt(this.idParam) : null,
            worldId: this.world.id,
            name: value.name,
            parentId: value.parentId < 0 ? null : value.parentId,
            type: value.type,
            description: value.description,
            climate: value.climate,
            features: value.features,
            area: value.population == "" ? null : value.area,
            population: value.population == "" ? null : value.population,
            additional: additional
        };

        if (this.isExisting) {
            this.modelService.update("places", body, data => { });
        } else {
            this.modelService.create("places", body, data => {
                this.router.navigate([this.nav.getSubRoute("places/" + data.id)]);
                this.ngOnInit();
            });
        }
    }

    addAdditional() {
        var value = this.additionalForm.value;
        if (!this.additionals.has(value.additional)) {
            this.additionals.set(value.additional, { value: "" });
        }
        this.additionalForm.setValue({
            additional: ""
        });
    }
    deleteAdditional(key: string) {
        this.additionals.delete(key);
    }

    editResidency(residency: any): void {
        if (residency == null) {
            this.modelService.getAll("characters", { worldId: this.world.id }, (data: any) => {
                this.dialog.open(ResidencyComponent, {
                    data: {
                        character: null,
                        place: { id: parseInt(this.idParam), name: this.placeForm.value.name },
                        characters: data,
                        places: null,
                        startDay: null,
                        endDay: null,
                        notes: "",
                        calendar: this.calendar
                    }
                }).afterClosed().subscribe(body => {
                    if (body) {
                        body.worldId = this.world.id;
                        this.modelService.create("residencies", body, (data: any) => { this.getResidencies(); });
                    }
                });
            });
        } else {
            this.dialog.open(ResidencyComponent, {
                data: {
                    character: residency.character,
                    place: residency.place,
                    characters: null,
                    places: null,
                    id: residency.id,
                    startDay: residency.startDay,
                    endDay: residency.endDay,
                    notes: residency.notes,
                    calendar: this.calendar
                }
            }).afterClosed().subscribe(body => {
                if (body) this.modelService.update("residencies", body, (data: any) => { this.getResidencies(); });
            });
        }
    }
    deleteResidency(residency: any): void {
        this.confirm("This action is not reversible.", "Delete residency?").subscribe(
            result => result && this.modelService.delete("residencies", residency.id, () => { this.getResidencies(); })
        );
    }

    editLocale(locale: any): void {
        if (locale == null) {
            this.modelService.getAll("events", { worldId: this.world.id }, (data: any) => {
                this.dialog.open(LocaleComponent, {
                    data: {
                        place: { id: parseInt(this.idParam), name: this.placeForm.value.name },
                        event: null,
                        places: null,
                        events: data,
                        role: "",
                        locales: this.locales.data
                    }
                }).afterClosed().subscribe(body => {
                    if (body) {
                        body.worldId = this.world.id;
                        this.relationService.create("locales", body, (data: any) => { this.getLocales(); });
                    }
                });
            });
        } else {
            this.dialog.open(LocaleComponent, {
                data: {
                    place: locale.place,
                    event: locale.event,
                    places: null,
                    events: null,
                    role: locale.role,
                    locales: this.locales.data
                }
            }).afterClosed().subscribe(body => {
                if (body) this.relationService.update("locales", body.place.id, body.event.id, body, (data: any) => { this.getLocales(); });
            });
        }
    }
    deleteLocale(locale: any): void {
        this.relationService.delete("locales", this.idParam, locale.event.id, () => { this.getLocales(); });
    }

    editPath(path: any): void {
        if (path == null) {
            this.dialog.open(PathComponent, {
                data: {
                    originId: parseInt(this.idParam),
                    destinationId: null,
                    title: "",
                    distance: null,
                    reversible: true,
                    notes: "",
                    places: this.places
                }
            }).afterClosed().subscribe(body => {
                if (body) {
                    this.modelService.create("paths", body, (data: any) => { this.getPaths(); });
                }
            });
        } else {
            this.dialog.open(PathComponent, {
                data: {
                    originId: path.origin.id,
                    destinationId: path.destination.id,
                    title: path.title,
                    distance: path.distance,
                    reversible: path.reversible,
                    notes: path.notes,
                    places: this.places
                }
            }).afterClosed().subscribe(body => {
                if (body) {
                    body.id = path.id;
                    this.modelService.update("paths", body, (data: any) => { this.getPaths(); });
                }
            });
        }
    }
    deletePath(path: any): void {
        this.confirm("This action is not reversible.", "Delete '" + path.title + "'?").subscribe(
            result => result && this.modelService.delete("paths", path.id, () => { this.getPaths(); })
        );
    }

    dateString(days: number) {
        if (!this.calendar) return days;
        return new CalendarDate(days, this.calendar).toString();
    }

    private getPlace(): void {
        this.idParam = this.route.snapshot.paramMap.get("placeId");

        if (this.idParam == "new") {
            this.isExisting = false;
            this.nav.setNavItems(null, []);
            this.placeForm.setValue({
                name: "",
                type: null,
                parentId: -1,
                description: "",
                climate: "",
                features: "",
                population: "",
                area: ""
            });
            this.map = {
                months: [],
                holidays: []
            };
            this.additionals = this.forge.objectToMapModel({});
        } else {
            this.isExisting = true;
            this.modelService.get("places", parseInt(this.idParam), data => {
                this.nav.setNavItems("places", [
                    new NavItem(data.name, "places/" + data.id)
                ]);
                this.placeForm.setValue({
                    name: data.name,
                    type: data.type,
                    parentId: data.parentId == null ? -1 : data.parentId,
                    description: data.description,
                    climate: data.climate,
                    features: data.features,
                    population: data.population ? data.population : "",
                    area: data.population ? data.population : ""
                });
                this.getLocales();
                this.getPaths();
                this.getResidencies();
                this.map = data.additional.__map__;
                delete data.additional.__map__;
                this.additionals = this.forge.objectToMapModel(data.additional);
            });
        }
    }

    private getCalendar(): void {
        if (!this.world.calendarId) return;
        this.modelService.get("calendars", this.world.calendarId, data => {
            this.calendar = data;
            this.calendar.daysInYear = new CalendarDate(null, this.calendar).getDaysInYear();
        });
    }

    private getLocales(): void {
        let id = parseInt(this.idParam);
        this.relationService.getAll(
            "locales",
            { placeId: id },
            data => {
                this.locales.data = data;
            }
        );
    }

    private getPlaces(): void {
        this.modelService.getAll(
            "places",
            { worldId: this.world.id },
            data => {
                let places = [];
                let childPlaces = [];
                for (let place of data) {
                    if (place.id != this.idParam) {
                        places.push(place);
                        if (place.parentId == this.idParam) childPlaces.push(place);
                    }
                }
                this.places = places;
                this.childPlaces.data = childPlaces;
            }
        );
    }

    private getPaths(): void {
        let id = parseInt(this.idParam);
        this.modelService.getAll(
            "paths",
            { originId: id },
            data => {
                this.paths.data = data;
            }
        );
    }

    private getResidencies(): void {
        let id = parseInt(this.idParam);
        this.modelService.getAll(
            "residencies",
            { placeId: id },
            data => {
                this.residencies.data = data;
            }
        );
    }
}
