import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DialogInfo } from 'src/app/logic/base';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'forge-confirm',
    templateUrl: './confirm.component.html'
})
export class ConfirmComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogInfo, public dialog: MatDialogRef<ConfirmComponent>) { }
}
