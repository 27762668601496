import { Injectable } from '@angular/core';
import { ForgeKeyValue } from '../base';

@Injectable({
    providedIn: 'root'
})
export class ForgeService {
    public readonly placeTypes: ForgeKeyValue<string>[] = [
        { key: "UNKNOWN", value: "Unknown" },
        { key: "BUILDING", value: "Building" },
        { key: "CITY", value: "City" },
        { key: "CONTINENT", value: "Continent" },
        { key: "COUNTRY", value: "Country" },
        { key: "GALAXY", value: "Galaxy" },
        { key: "ISLAND", value: "Island" },
        { key: "LANDMARK", value: "Landmark" },
        { key: "OCEAN", value: "Ocean" },
        { key: "PLANET", value: "Planet" },
        { key: "SEA", value: "Sea" },
        { key: "SYSTEM", value: "System" },
        { key: "TOWN", value: "Town" },
        { key: "VILLAGE", value: "Village" },
        { key: "REGION", value: "Region" },
        { key: "NEIGHBORHOOD", value: "Neighborhood" }
    ];
    public readonly questionTypes = {
        "TEXT": "Text",
        "YESNO": "Yes/No"
    };
    public readonly genders = {
        "MALE": "Male",
        "FEMALE": "Female",
        "NON_BINARY": "Non-binary/Other"
    };
    public readonly readingPlatforms = {
        "UNKNOWN": "Unspecified",
        "WORD": "Microsoft Word",
        "GDOCS": "Google Docs"
    };

    public readonly editorConfig = {
        placeholder: '',
        tabsize: 2,
        height: 100,
        uploadImagePath: '',
        toolbar: [
            ['misc', ['undo', 'redo']],
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
            ['fontsize', ['fontsize', 'color']],
            ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
            ['insert', ['link', 'picture', 'video', 'hr']]
        ],
        fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
    };

    public getTimestamp(): number {
        return Math.round((new Date()).getTime() / 1000);
    }

    public getPlainText(note: any): string {
        let html: string = note.text;
        html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
        html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
        html = html.replace(/<\/div>/ig, '\n');
        html = html.replace(/<\/li>/ig, '\n');
        html = html.replace(/<\/ul>/ig, '\n');
        html = html.replace(/<\/p>/ig, '\n');
        html = html.replace(/<br\s*[\/]?>/gi, '\n');
        html = html.replace(/<[^>]+>/ig, '');
        let len = html.indexOf('\n');
        if (len < 0) len = html.length;
        html = html.trim().substring(0, len);
        if (html.length > 120) {
            html = html.substring(0, 119) + '…';
        }
        return html;
    }

    public objectToMapModel(obj: any): Map<string, any> {
        let keys: string[] = Object.keys(obj);
        let ret = new Map<string, any>();
        for (let key of keys) {
            ret.set(key, { value: obj[key]});
        }
        return ret;
    }

    public mapModelToObject(model: Map<string, any>): any {
        return Array.from(model).reduce((obj, [key, value]) => (
            Object.assign(obj, { [key]: value.value })
        ), {});
    }

    public sqlDateToJsDate(sqlDate: string): Date {
        return new Date(sqlDate);
    }

    public jsDateToSqlDate(date: Date): string {
        return date.getUTCFullYear() + '-' + this.padDateComponent(date.getUTCMonth() + 1) + '-' + this.padDateComponent(date.getUTCDate());
    }

    private padDateComponent(num: number): string {
        return ('00' + num).slice(-2);
    }
}
