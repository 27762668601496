import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthInfo } from '../base';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AlertComponent } from 'src/app/dialogs/alert/alert.component';
import { LoginComponent } from 'src/app/dialogs/login/login.component';
import { CountdownComponent } from 'src/app/dialogs/countdown/countdown.component';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
    private authInfo: AuthInfo = null;
    private pendingRequests: (() => void)[] = [];

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private http: HttpClient,
        @Inject(LOCAL_STORAGE) private storage: StorageService
    ) {
        if (this.storage.has("token")) {
            var cookieAuthInfo: AuthInfo = {
                token: this.storage.get("token"),
                displayName: this.storage.get("displayName")
            }
            this.authInfo = cookieAuthInfo;
        }
    }

    public getApiUrl(path: string): string {
        return environment.apiUrl + path;
    }

    public isAuthenticated(): boolean {
        return this.getAuth() != null;
    }

    public getAuth(): AuthInfo {
        return this.authInfo;
    }

    public signIn(username: string, password: string): Observable<AuthInfo> {
        return new Observable<AuthInfo>((observer) => {
            this.http.post(this.getApiUrl("auth"), {
                username: username,
                password: password
            }).subscribe(
                (data: AuthInfo) => {
                    var newAuthInfo: AuthInfo = {
                        token: data["token"],
                        displayName: data["displayName"]
                    };
                    this.storage.set("token", newAuthInfo.token);
                    this.storage.set("displayName", newAuthInfo.displayName);
                    this.authInfo = newAuthInfo;
                    this.pendingRequests.forEach(req => { req(); });
                    this.pendingRequests = [];

                    observer.next(newAuthInfo);
                    observer.complete();
                },
                (error: HttpErrorResponse) => {
                    this.alert("Please make sure you have provided correct credentials.", "Sign-in failed");
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public signOut(): void {
        this.storage.remove("token");
        this.storage.remove("displayName");
        this.authInfo = null;
    }

    public processHttpError(error: HttpErrorResponse, req: () => void) {
        switch(error.status) {
            case 401:
                this.pendingRequests.push(req);
                this.signOut();
                this.showSignInDialog();
                break;
            case 403:
                this.alert("Please make sure you have the right permissions.", "Access denied");
                break;
            case 400:
            case 500:
                var message = error.error.message;
                if (message[message.length - 1] != '.') {
                    message += '.';
                }
                this.alert(message);
                break;
            case 404:
                this.router.navigate(["/not-found"]);
                break;
            default:
                this.countdown("A client error occurred while processing the page. Retrying in {} seconds.")
                    .subscribe(result => req());
        }
    }

    private alert(message: string, title: string = "Attention"): Observable<any> {
        var dialog = this.dialog.open(AlertComponent, {
            data: { message: message, title: "Attention" }
        });
        return dialog.afterClosed();
    }

    private countdown(message: string): Observable<any> {
        var dialog = this.dialog.open(CountdownComponent, {
            data: { message: message, title: "Attention", count: 20 },
            disableClose: true,
        });
        return dialog.afterClosed();
    }

    private showSignInDialog(): void {
        this.dialog.open(
            LoginComponent,
            {
                autoFocus: true,
                backdropClass: "login-backdrop",
                disableClose: true,
                width: "300px"
            }
        );
    }
}
