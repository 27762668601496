import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ApiModelService } from 'src/app/logic/services/api-model.service';
import { ActivatedRoute } from '@angular/router';
import { BigTableColumns } from 'src/app/logic/utils';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { WorldService } from 'src/app/logic/services/world.service';
import { ForgeNavService } from 'src/app/logic/services/forge-nav.service';
import { BasePage } from '../base-page';

@Component({
    selector: 'app-books',
    templateUrl: './books.component.html'
})
export class BooksComponent extends BasePage implements OnInit {
    @ViewChild('booksSort', { static: true }) booksSort: MatSort;
    booksCols: BigTableColumns = new BigTableColumns([], ['subtitle', 'author']);
    books = new MatTableDataSource([]);

    @ViewChild('seriesSort', { static: true }) seriesSort: MatSort;
    seriesCols: BigTableColumns = new BigTableColumns([], ['author']);
    series = new MatTableDataSource([]);

    constructor(
        private modelService: ApiModelService,
        private route: ActivatedRoute,
        public world: WorldService,
        public nav: ForgeNavService,
        dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit() {
        var worldId: string = this.route.snapshot.paramMap.get("worldId");
        this.modelService.get("worlds", parseInt(worldId), data => {
            this.world.set(data);
            this.nav.setNavItems("books", []);
            this.getBooks();
            this.getSeries();
        });
        this.books.sort = this.booksSort;
        this.series.sort = this.seriesSort;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.booksCols.update();
        this.seriesCols.update();
    }

    deleteBook(book: any): void {
        this.confirm("This action is not reversible.", "Delete '" + book.title + "'?").subscribe((result) => {
            result && this.modelService.delete("books", book.id, () => this.getBooks());
        });
    }

    deleteSeries(series: any): void {
        this.confirm("This action is not reversible.", "Delete '" + series.title + "'?").subscribe((result) => {
            result && this.modelService.delete("series", series.id, () => this.getSeries());
        });
    }

    private getBooks(): void {
        this.modelService.getAll(
            "books",
            { worldId: this.world.id },
            data => {
                let displayedColumns = ['title', 'subtitle', 'author'];
                if (this.world.canDelete()) displayedColumns.push('actions');
                this.booksCols.columns = displayedColumns;
                this.booksCols.update();
                this.books.data = data;
            }
        );
    }

    private getSeries(): void {
        this.modelService.getAll(
            "series",
            { worldId: this.world.id },
            data => {
                let displayedColumns = ['title', 'author'];
                if (this.world.canDelete()) displayedColumns.push('actions');
                this.seriesCols.columns = displayedColumns;
                this.seriesCols.update();
                this.series.data = data;
            }
        );
    }
}
