import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { WorldService } from 'src/app/logic/services/world.service';

@Component({
    selector: 'app-role',
    templateUrl: './role.component.html'
})
export class RoleComponent {
    loadingSub: Subscription;

    roleForm = new FormGroup({
        characterId: new FormControl(null, [Validators.required]),
        bookId: new FormControl(null, [Validators.required]),
        role: new FormControl("", []),
        priority: new FormControl("", [Validators.pattern("[0-9]*")]),
    });

    availableCharacters = [];
    availableBooks = [];
    title = "";

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public world: WorldService,
        public dialog: MatDialogRef<RoleComponent>
    ) {
        this.roleForm.setValue({
            characterId: this.data.character ? this.data.character.id : null,
            bookId: this.data.book ? this.data.book.id : null,
            role: this.data.role,
            priority: this.data.priority
        });
        if (data.characters) {
            this.title = "Add Role";
            for (let character of data.characters) {
                let found: boolean = false;
                for (let role of data.roles) {
                    if (role.character.id == character.id) {
                        found = true;
                        break;
                    }
                }
                if (!found) this.availableCharacters.push(character);
            }
        } else if (data.books) {
            this.title = "Role: " + data.character.name;
            for (let book of data.books) {
                let found: boolean = false;
                for (let role of data.roles) {
                    if (role.book.id == book.id) {
                        found = true;
                        break;
                    }
                }
                if (!found) this.availableBooks.push(book);
            }
        } else {
            this.title = data.character.name + " (" + data.book.title + ")";
        }
    }

    onSubmit() {
        var value = this.roleForm.value;
        var body = {
            worldId: this.world.id,
            character: {
                id: value.characterId
            },
            book: {
                id: value.bookId
            },
            description: value.role,
            priority: (!value.priority || value.priority == "") ? null : parseInt(value.priority)
        };
        this.dialog.close(body);
    }
}
